import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Pagination from 'rc-pagination'
import { Select } from '@creditinfo-ui/atoms'

import Icon from '../Icon'

interface Props {
  titleMessageId?: string
  onAddCode?: Function
  onRemoveCode?: Function
  setPagination: Function
  codes: Company[]
  pagination: GroupPagination
}

export default function SelectGroup ({ titleMessageId, onAddCode, onRemoveCode, setPagination, codes = [], pagination }: Props): JSX.Element {
  const intl = useIntl()

  const [showCodes, setShowCodes] = useState(true)

  const sortSelects: SelectItem[] = [
    {
      value: 'AZ',
      label: intl.formatMessage({ id: 'selectCompanies.select.sortAZ' })
    },
    {
      value: 'ZA',
      label: intl.formatMessage({ id: 'selectCompanies.select.sortZA' })
    }
  ]

  const pages = useMemo(() => {
    return Math.ceil(pagination.total / pagination.limit)
  }, [pagination])

  function onAddCodeClick (code: object): any {
    if (onAddCode === undefined) return

    onAddCode(code)
  }

  function onRemoveCodeClick (code: object): any {
    if (onRemoveCode === undefined) return

    onRemoveCode(code)
  }

  function onToggleShow (): void {
    setShowCodes(!showCodes)
  }

  function paginationItemRender (current: any, type: any, element: any): JSX.Element {
    const dots = '...'

    switch (type) {
      case 'prev':
        return (
          <a className="h-8 w-8 flex items-center justify-center text-gray-brand bg-silver cursor-pointer">
            <Icon type='page_previous' />
          </a>
        )
      case 'next':
        return (
          <a className="h-8 w-8 flex items-center justify-center text-gray-brand bg-silver cursor-pointer">
            <Icon type='page_next' />
          </a>
        )
      case 'jump-prev':
        return (
          <a className="text-teal-brand cursor-pointer">
            { dots }
          </a>
        )
      case 'jump-next':
        return (
          <a className="text-teal-brand cursor-pointer">
            { dots }
          </a>
        )
      case 'page':
        return (
          <a className={`${pagination.page === current ? 'font-bold' : 'cursor-pointer text-teal-brand'}`}>
            { current }
          </a>
        )
      default:
        return element
    }
  }

  return (
    <div className="mb-16">
      {titleMessageId !== undefined
        ? (
          <div
            className={`py-4 px-6 flex items-center gap-2 font-bold uppercase ${(onRemoveCode !== undefined) ? 'text-red-brand border-t-red-brand' : 'border-t-gray-brand'}  border-t-[3px] border-b border-b-grey-light cursor-pointer`}
            onClick={onToggleShow}
          >
            <Icon type={showCodes ? 'chevron_up' : 'chevron_down'} />

            <FormattedMessage id={titleMessageId} />
          </div>
          )
        : null}

      {showCodes
        ? (
          <>
            {titleMessageId !== undefined && codes.length > 0
              ? (
                <div className="my-6 md:my-12 flex flex-col md:flex-row items-start md:items-center gap-6">
                  <div className="py-4 px-6 flex items-center gap-6 bg-silver">
                    <FormattedMessage id="selectCompanies.select.show" />

                    {[10, 25, 50, 100].map(x =>
                      (
                        <a
                          className={`${pagination.limit === x ? 'font-bold' : 'text-teal-brand'}  cursor-pointer`}
                          key={x}
                          onClick={function () { setPagination({ ...pagination, page: 1, limit: x }) }}
                        >
                          {x}
                        </a>
                      )
                    )}
                  </div>

                  <Select
                    isCombobox={false}
                    items={sortSelects.map(x => x.label)}
                    nilMessage={sortSelects.find(z => z.value === pagination.sort)?.label}
                    onChange={function (e) { setPagination({ ...pagination, page: 1, sort: sortSelects.find(z => z.label === e)?.value ?? 'AZ' }) }}
                  />
                </div>
                )
              : null}

            <div className="mt-6 flex flex-wrap gap-3">
              {codes.map(code => (
                <div
                  className={`py-1 px-3 flex items-center gap-2 rounded ${onRemoveCode === undefined && code.selected !== true ? 'bg-gray-light' : 'text-white bg-teal-brand'}`}
                  key={code.regCode}
                >
                  {code.companyName === undefined ? code.regCode : code.companyName}

                  {onAddCode !== undefined && code.selected !== true
                    ? (
                      <div
                        className="cursor-pointer"
                        onClick={function () { onAddCodeClick(code) }}
                      >
                        <Icon type="add" />
                      </div>
                      )
                    : null}

                  {onRemoveCode !== undefined
                    ? (
                      <div
                        className="cursor-pointer"
                        onClick={function () { onRemoveCodeClick(code) }}
                      >
                        <Icon type="close" />
                      </div>
                      )
                    : null}
                </div>
              ))}
            </div>

            {titleMessageId !== undefined && pagination.limit > 0 && pages > 1
              ? (
                <div className="w-full mt-8 py-4 flex items-center">
                  <a
                    className="h-8 w-8 mr-4 flex items-center justify-center text-gray-brand bg-silver cursor-pointer"
                    onClick={function () { setPagination({ ...pagination, page: 1 }) }}
                  >
                    <Icon type='page_first' />
                  </a>

                  <Pagination
                    className="flex gap-4"
                    current={pagination.page}
                    itemRender={paginationItemRender}
                    onChange={function (page: number) { setPagination({ ...pagination, page }) }}
                    pageSize={pagination.limit}
                    showTitle={false}
                    total={pagination.total}
                  />

                  <a
                    className="h-8 w-8 flex items-center justify-center text-gray-brand bg-silver cursor-pointer"
                    onClick={function () { setPagination({ ...pagination, page: pages }) }}
                  >
                    <Icon type='page_last' />
                  </a>
                </div>
                )
              : null}
          </>
          )
        : null}
    </div>
  )
}
