export const defaultBorders = {
	widths: {
		sm: '1px',
		md: '2px',
		lg: '3px',
		xxl: '1rem',
	},
	radii: {
		badge: '10px',
		basic: '5px',
		round: '50%',
	},
} as const;

export type Borders = typeof defaultBorders;

export const defaultNativeBorders = {
	widths: {
		sm: 1,
		md: 2,
	},
	radii: {
		basic: 5,
	},
} as const;

export type NativeBorders = typeof defaultNativeBorders;
