import { ReactNode, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Text } from '@creditinfo-ui/atoms'

import { apiGet } from '../../hooks/useApi'
import Menu from '../Menu'
import StepsIndicator from './StepsIndicator'
import Filter from '../Filter'
import CompanySelect from '../CompanySelect'

type Page = 'global-list' | 'companies-list'

interface Props {
  tabs: string[]
  filterValues: FilterValue[]
  forInternational?: boolean
  codesUploaded: boolean
  codesAccepted: number
  codesNotExist: number
  codesIncompleteData: number
  setFilterValues: Function
  setCodesUploaded: Function
  setCodesAccepted: Function
  setCodesIncompleteData: Function
  setCodesNotExist: Function
  page: Page
}

export default function StepSelecFilters ({ tabs, page, filterValues, forInternational = false, codesUploaded, codesAccepted, codesNotExist, codesIncompleteData, setFilterValues, setCodesUploaded, setCodesAccepted, setCodesIncompleteData, setCodesNotExist }: Props): JSX.Element {
  const intl = useIntl()
  const intlCompaniesTargetListPageLoad = JSON.parse(localStorage.getItem('intlCompaniesTargetListPageLoad') ?? 'false') &&
  window.location.pathname.includes('select-sample') && !filterValues.length

  const [filterKey, setFilterKey] = useState(Math.random())
  const [activeCategoryId, setActiveCategoryId] = useState(0)
  const [activeFilterId, setActiveFilterId] = useState(0)
  const [filterCategories, setFilterCategories] = useState<DataCategory[]>([])

  const menuItems: MenuItem[] = useMemo(() => {
    if (filterCategories.length === 0) return []

    if (forInternational) {
      return filterCategories.map(x => ({
        id: x.categoryID,
        label: x.category,
        icon: x.iconName,
        childs: x.filters.map(y => ({
          id: y.filterID,
          label: y.filterText
        }))
      }))
    }

    const companyFilterMenu: MenuItem = {
      id: -1,
      label: intl.formatMessage({ id: 'selectFilters.menuRemoveCompanies' }),
      icon: 'remove'
    }

    return [
      ...filterCategories.map(x => ({
        id: x.categoryID,
        label: x.category,
        icon: x.iconName,
        childs: x.filters.map(y => ({
          id: y.filterID,
          label: y.filterText
        }))
      })),
      companyFilterMenu
    ]
  }, [filterCategories])

  const filter: Filter | undefined = useMemo(() => filterCategories.find(x => x.categoryID === activeCategoryId)?.filters?.find(x => x.filterID === activeFilterId), [filterCategories, activeCategoryId, activeFilterId])

  const filterSelectedValues = useMemo(() => filterValues.filter(x => x.filterName === filter?.filterName), [filter, filterValues])

  const activeMenuItemIds = useMemo(() => {
    if (codesAccepted > 0) {
      return [...filterValues.map(x => x.filterID), -1]
    } else {
      return filterValues.map(x => x.filterID)
    }
  }, [menuItems, filterValues, codesAccepted])

  useEffect(() => {
    async function getFilters (): Promise<void> {
      const { allFilters, error } = await apiGet('getFilters', {
        params: {
          forInternational: forInternational ? 1 : 0
        }
      })

      if (error !== undefined) return

      // This should be done in backend side.
      // Removes unwanted filter options from nested filters array.
      const removedUnwantedFilterOptions = allFilters.map((category: any) => ({
        ...category,
        filters: category.filters.map((filter: Filter) => ({
          ...filter,
          filterOptions: filter.filterOptions.filter((option: FilterOption) =>
            !(option.optionCode === '698' ||
              option.optionText === 'apartment association' ||
              option.optionText === 'korteriühistu')
          )
        }))
      }))

      setFilterCategories(removedUnwantedFilterOptions)
    }

    getFilters().catch(console.error)
  }, [intl.locale])

  function onMenuClick (item: MenuItem, child?: MenuItem): void {
    if (item.id !== -1 && child === undefined) return

    localStorage.setItem('intlCompaniesTargetListPageLoad', 'false')
    setActiveCategoryId(item.id)
    setActiveFilterId(child?.id ?? -1)
  }

  function flattenAndGetValuesText (filter: Filter, value: string[]): string[] {
    const traverse = (filterOption: FilterOption): string[] => {
      let results = []

      if (value.includes(filterOption.optionCode)) {
        results.push(filterOption.optionText)
      }

      if (filterOption.node) {
        results = results.concat(filterOption.node.map(traverse).flat())
      }
      return results
    }

    return filter.filterOptions.map(traverse).flat()
  }

  function onFilterChange (filter: Filter, value: string[]): void {
    const newFilterValue: FilterValue = {
      filterID: filter.filterID,
      filterName: filter.filterName,
      filterText: filter.filterText,
      filterType: filter.filterType,
      filterValues: value,
      filterValuesText: flattenAndGetValuesText(filter, value)
    }

    if (value.length === 0) {
      setFilterValues(filterValues.filter(x => x.filterName !== filter.filterName))
    } else if (filterValues.some(x => x.filterName === filter.filterName)) {
      setFilterValues([...filterValues.filter(x => x.filterName !== filter.filterName), newFilterValue])
    } else {
      setFilterValues([...filterValues, newFilterValue])
    }
  }

  const getIntlMessageText = (page: Page): string => {
    switch (page) {
      case 'companies-list': return 'selectSample.step.intlMsg'
      case 'global-list': return 'globalSample.step.intlMsg'
    }
  }

  return (
    <div className="w-full my-14 lg:ml-96 lg:px-11 place-self-start">
      <div className="mb-8 md:mb-14">
        <Text
          message={{ id: 'selectFilters.title' }}
          variant="viewTitle"
        />
      </div>

      <StepsIndicator
        activeStep={0}
        steps={tabs}
      />

      {!(menuItems.length === 0) && (
        <Menu
          activeItemIds={activeMenuItemIds}
          intlOpenItemsIds={menuItems?.length && intlCompaniesTargetListPageLoad ? [menuItems[0].id] : undefined}
          items={menuItems}
          onClick={onMenuClick}
          selectedFilters={filterValues}
          titleMessageId="selectFilters.menuTitle"
        />
      )}

      {intlCompaniesTargetListPageLoad || activeFilterId === -2
        ? (
          <div className="text-gray-brand">
            <FormattedMessage
              id={`${getIntlMessageText(page)}1`}
              values={{
                b: (chunks: ReactNode[]) => (
                  <strong>
                    {chunks}
                  </strong>
                )
              }}
            />

            <div className="mt-6">
              <FormattedMessage
                id={`${getIntlMessageText(page)}2`}
                values={{
                  b: (chunks: ReactNode[]) => (
                    <strong>
                      {chunks}
                    </strong>
                  )
                }}
              />
            </div>

            <div className="mt-6">
              <FormattedMessage
                id={`${getIntlMessageText(page)}3`}
                values={{
                  b: (chunks: ReactNode[]) => (
                    <strong>
                      {chunks}
                    </strong>
                  )
                }}
              />
            </div>

            <div className="mt-6">
              <FormattedMessage
                id={`${getIntlMessageText(page)}4`}
                values={{
                  b: (chunks: ReactNode[]) => (
                    <strong>
                      {chunks}
                    </strong>
                  )
                }}
              />
            </div>
          </div>
          )
        : null}

      {filter !== undefined
        ? (
          <>
            <div className="mb-8 md:mb-12 pt-8 font-bold border-t-[3px] border-red-brand text-[18px]">
              {filter.filterText}

              <div className="mt-6 md:mt-0 md:float-right">
                <Button
                  isDisabled={filterSelectedValues.length === 0}
                  onClick={function () { setFilterKey(Math.random()); onFilterChange(filter, []) }}
                  type="button"
                  variant={['primary', { isOutlined: true }]}
                >
                  <FormattedMessage id="selectFilters.filter.reset" />
                </Button>
              </div>
            </div>

            <Filter
              diagram={filter.diagram}
              filterName={filter.filterName}
              filterText={filter.filterText}
              key={filterKey}
              max={filter.interval.max}
              min={filter.interval.min}
              name={filter.filterName}
              onChange={function (value: string[]) { onFilterChange(filter, value) }}
              options={filter.filterOptions}
              type={filter.filterType}
              value={filterValues.find(x => x.filterName === filter.filterName)?.filterValues ?? []}
            />
          </>
          )
        : null}

      {activeCategoryId === -1
        ? (
          <CompanySelect
            codesAccepted={codesAccepted}
            codesIncompleteData={codesIncompleteData}
            codesNotExist={codesNotExist}
            codesUploaded={codesUploaded}
            setCodesAccepted={function (count: number) { setCodesAccepted(count) }}
            setCodesIncompleteData={function (count: number) { setCodesIncompleteData(count) }}
            setCodesNotExist={function (count: number) { setCodesNotExist(count) }}
            setCodesUploaded={function (value: boolean) { setCodesUploaded(value) }}
          />
          )
        : null}
    </div>
  )
}
