import { Text } from '@creditinfo-ui/atoms'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/Layout'

export default function NotFound (): JSX.Element {
  return (
    <Layout>
      <div className="py-20 md:px-36 flex flex-col md:flex-row max-w-7xl gap-16 bg-silver">
        <div className="text-red-brand flex items-center justify-center md:justify-end">
          <svg
            fill="none"
            height="125"
            viewBox="0 0 125 125"
            width="125"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M117.938 18.5H111V0H13.875V18.5H6.9375C3.2375 18.5 0 21.5063 0 25.4375V87.875C0 91.575 3.2375 94.8125 6.9375 94.8125H46.4799L44.6299 111H41.6237C35.1487 111 30.0612 116.088 30.0612 122.562V124.875H94.8112V122.562C94.8112 116.088 89.7237 111 83.2487 111H80.2424L78.3924 94.8125H117.935C121.866 94.8125 124.872 91.575 124.872 87.875V25.4375C124.872 21.5063 121.866 18.5 117.935 18.5H117.938ZM18.5 13.875H106.375V80.9375H18.5V13.875ZM4.625 25.4375C4.625 24.2812 5.78125 23.125 6.9375 23.125H13.875V80.9375H4.625V25.4375ZM49.2576 111L51.1076 94.8125H73.5388L75.3888 111H49.2576ZM120.25 80.9375H111V23.125H117.938C119.094 23.125 120.25 24.2812 120.25 25.4375V80.9375Z"
              fill="currentColor"
            />

            <path
              d="M51.1049 36.7701H46.7112L36.5362 50.4138L36.9987 52.7263C36.9987 52.9576 37.2299 53.1888 37.4612 53.4201C37.6924 53.6513 37.9237 53.6513 38.1549 53.6513H46.9424V58.7388H50.8737V53.6513H52.2612C52.4924 53.6513 52.7237 53.6513 52.9549 53.4201C53.1862 53.1888 53.1862 52.9576 53.1862 52.7263V50.1826H50.8737V36.7701H51.1049ZM47.1737 43.2451V44.4013V50.1826H41.6237L47.4049 42.0888C47.1737 42.3201 47.1737 42.7826 47.1737 43.2451Z"
              fill="currentColor"
            />

            <path
              d="M68.45 39.0799C67.7563 38.1549 66.8313 37.4612 65.9063 36.9987C64.9813 36.5362 63.825 36.3049 62.6688 36.3049C61.5125 36.3049 60.3563 36.5362 59.4313 36.9987C58.5063 37.4612 57.5813 38.1549 56.8875 39.0799C56.1938 40.0049 55.5 41.1612 55.2688 42.5487C54.8063 43.9362 54.575 45.5549 54.575 47.4049C54.575 49.2549 54.8063 50.8737 55.2688 52.2612C55.7313 53.6487 56.1938 54.8049 56.8875 55.7299C57.5813 56.6549 58.5063 57.3487 59.4313 57.8112C60.3563 58.2737 61.5125 58.5049 62.6688 58.5049C63.825 58.5049 64.9813 58.2737 65.9063 57.8112C66.8313 57.3487 67.7563 56.6549 68.45 55.7299C69.1438 54.8049 69.8375 53.6487 70.0688 52.2612C70.5313 50.8737 70.7625 49.2549 70.7625 47.4049C70.7625 45.5549 70.5313 43.9362 70.0688 42.5487C69.6063 41.1612 69.1438 40.0049 68.45 39.0799ZM65.675 51.3362C65.4438 52.2612 65.2125 52.9549 64.9813 53.6487C64.75 54.1112 64.2875 54.5737 63.825 54.8049C63.3625 55.0362 63.1313 55.0362 62.6688 55.0362C62.2063 55.0362 61.7438 55.0362 61.5125 54.8049C61.05 54.5737 60.8188 54.1112 60.3563 53.6487C60.125 53.1862 59.8938 52.2612 59.6625 51.3362C59.4313 50.4112 59.4313 49.2549 59.4313 47.6362C59.4313 46.0174 59.4313 44.8612 59.6625 43.9362C59.8938 43.0112 60.125 42.3174 60.3563 41.6237C60.5875 41.1612 61.05 40.6987 61.5125 40.4674C61.975 40.2362 62.2063 40.2362 62.6688 40.2362C63.1313 40.2362 63.5938 40.2362 63.825 40.4674C64.2875 40.6987 64.5188 41.1612 64.9813 41.6237C65.2125 42.0862 65.4438 43.0112 65.675 43.9362C65.9063 44.8612 65.9063 46.2487 65.9063 47.6362C65.9063 49.0237 65.9063 50.4112 65.675 51.3362Z"
              fill="currentColor"
            />

            <path
              d="M86.025 36.7701H81.6313L71.4563 50.4138L71.9188 52.7263C71.9188 52.9576 72.15 53.1888 72.3813 53.4201C72.6125 53.6513 72.8438 53.6513 73.075 53.6513H81.8625V58.7388H85.7938V53.6513H87.1813C87.4125 53.6513 87.6438 53.6513 87.875 53.4201C88.1063 53.1888 88.1063 52.9576 88.1063 52.7263V50.1826H85.7938V36.7701H86.025ZM81.8625 43.2451V44.4013V50.1826H76.3125L82.0938 42.0888C82.0938 42.3201 81.8625 42.7826 81.8625 43.2451Z"
              fill="currentColor"
            />
          </svg>
        </div>

        <div className="px-8 md:px-0">
          <Text
            message={{ id: 'notFound.title' }}
            variant="extraTitle"
          />

          <div className="mt-6 text-teal-brand">
            <ul>
              <li>
                <Link to="/">
                  <FormattedMessage id="notFound.start" />
                </Link>
              </li>

              <li>
                <Link to="/own-sample">
                  <FormattedMessage id="home.ownSample.title" />
                </Link>
              </li>

              <li>
                <Link to="/select-sample">
                  <FormattedMessage id="home.selectSample.title" />
                </Link>
              </li>

              <li>
                <Link to="/global-sample">
                  <FormattedMessage id="home.globalSample.title" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
