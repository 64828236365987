interface Props {
  steps: string[]
  activeStep: number
  titleClassName?: string
}

export default function StepsIndicator ({ steps, activeStep, titleClassName = 'hidden md:block' }: Props): JSX.Element {
  return (
    <div className="mb-8 md:my-12 font-bold flex flex-row md:items-center justify-start gap-6 md:gap-12">
      { steps.map((x, idx) => (
        <div
          className="flex items-center gap-4"
          key={x}
        >
          <div className={`h-14 w-14 md:h-[41px] md:w-[41px] text-[20px] flex items-center justify-center rounded-full ${idx === activeStep ? 'bg-red-brand text-white' : 'bg-gray-light'} `}>
            {idx + 1}
          </div>

          <span className={`${titleClassName} text-[20px]`}>
            { x }
          </span>
        </div>
      ))}
    </div>
  )
}
