import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Text } from '@creditinfo-ui/atoms'

import { apiPost } from '../hooks/useApi'
import { useSessionStorage } from '../hooks/useStorage'
import Layout from '../components/Layout'

export default function PaymentCallback (): JSX.Element {
  const [activePage] = useSessionStorage('activePage', '/')
  const [searchParams, setSearchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  useEffect(() => {
    async function getStatus (): Promise<void> {
      const token = searchParams.get('order-token')

      const { status, email, error } = await apiPost('paymentStatus', {
        body: {
          paymentToken: token
        }
      })

      if (error !== undefined) return

      setStatus(status)
      setEmail(email)
    }

    getStatus().catch(console.error)
    setSearchParams({})
  }, [])

  return (
    <Layout>
      <div className="h-full flex flex-col justify-center items-center gap-2">
        {status !== ''
          ? status === 'success'
            ? (
              <>
                <div className="mb-14 text-green-brand">
                  <svg
                    fill="none"
                    height="185"
                    viewBox="0 0 185 185"
                    width="185"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M92.5 185C41.4323 185 0 143.568 0 92.5C0 41.4323 41.4323 0 92.5 0C143.568 0 185 41.4323 185 92.5C185 143.568 143.568 185 92.5 185ZM92.5 10.2135C47.2135 10.2135 10.2135 47.2135 10.2135 92.5C10.2135 137.786 47.2135 174.786 92.5 174.786C137.786 174.786 174.786 137.786 174.786 92.5C174.786 47.2135 137.786 10.2135 92.5 10.2135Z"
                      fill="currentColor"
                    />

                    <path
                      d="M82.479 117.552C81.1294 117.552 79.7812 116.974 78.8172 116.011L59.162 96.354C57.2344 94.4264 56.8499 91.1506 58.584 89.2242C60.5116 86.9121 63.9798 86.7185 66.0996 88.8384L82.4805 105.219H82.6728L119.095 68.7974C121.022 66.8698 124.105 66.4853 126.224 68.2194C128.537 70.147 128.73 73.6152 126.61 75.735L86.1415 116.204C85.1777 117.166 83.8294 117.552 82.4797 117.552L82.479 117.552Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>

                <Text
                  message={{ id: 'paymentCallback.title' }}
                  variant="extraTitle"
                />

                <FormattedMessage
                  id="paymentCallback.text"
                  values={{ email }}
                />

                <a
                  className="mt-2 text-teal-brand font-bold"
                  href="/"
                >
                  <FormattedMessage id="paymentCallback.backLink" />
                </a>
              </>
              )
            : (
              <>
                <div className="mb-14 text-red-alert">
                  <svg
                    fill="none"
                    height="185"
                    viewBox="0 0 208 185"
                    width="208"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M205.131 157.196L119.832 9.26714C116.533 3.55009 110.393 0 103.803 0C97.212 0 91.0719 3.55032 87.7732 9.26714L2.47398 157.196C-0.82466 162.913 -0.82466 170.016 2.47398 175.733C5.76601 181.45 11.9066 185 18.5034 185H189.102C195.699 185 201.839 181.45 205.131 175.733C208.437 170.014 208.437 162.913 205.138 157.196H205.131ZM196.907 170.978C195.301 173.761 192.31 175.49 189.097 175.49L18.4989 175.492C15.2863 175.492 12.2943 173.761 10.6896 170.98C9.08223 168.195 9.08223 164.737 10.6896 161.952L95.9889 14.0226C97.5963 11.2394 100.588 9.51062 103.798 9.51062C107.008 9.51062 109.998 11.2416 111.607 14.0226L196.907 161.952C198.514 164.735 198.514 168.192 196.904 170.978L196.907 170.978ZM103.802 61.4643C101.181 61.4643 99.0545 63.5931 99.0545 66.2172V118.508C99.0545 121.132 101.181 123.261 103.802 123.261C106.422 123.261 108.549 121.132 108.549 118.508V66.2172C108.549 63.5953 106.422 61.4643 103.802 61.4643ZM103.802 135.681C101.181 135.681 99.0545 137.81 99.0545 140.434V150.47C99.0545 153.094 101.181 155.223 103.802 155.223C106.422 155.223 108.549 153.094 108.549 150.47V140.434C108.549 137.81 106.422 135.681 103.802 135.681Z"
                      fill="#EF0000"
                    />
                  </svg>
                </div>

                <Text
                  message={{ id: 'paymentCallback.error.title' }}
                  variant="extraTitle"
                />

                <FormattedMessage
                  id="paymentCallback.error.text"
                  values={{ email }}
                />

                <a
                  className="mt-2 text-teal-brand font-bold"
                  href={activePage}
                >
                  <FormattedMessage id="paymentCallback.error.backLink" />
                </a>
              </>
              )
          : null}
      </div>
    </Layout>
  )
}
