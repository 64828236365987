import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import { Button, Textarea } from '@creditinfo-ui/atoms'

import store from '../../store'
import { setErrors } from '../../store/errors'
import { apiGet, apiPost, apiPostForm } from '../../hooks/useApi'
import { db, resetDb } from '../../hooks/useDexieDB'

interface Props {
  setCodesUploaded: Function
  setCodesAccepted: Function
  setCodesIncompleteData: Function
  setCodesNotExist: Function
}

export default function CompanySelectUpload ({ setCodesUploaded, setCodesAccepted, setCodesIncompleteData, setCodesNotExist }: Props): JSX.Element {
  const intl = useIntl()

  const [uploading, setUploading] = useState(false)
  const [sampleFile, setSampleFile] = useState('')
  const [codesInput, setCodesInput] = useState('')

  const maxFileSize = 1024 * 1024 * 10

  const onDrop = useCallback(onAddFile, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: codesInput.trim() !== '',
    multiple: false,
    accept: {
      'text/csv': []
    },
    validator: (file) => {
      if (file.size > maxFileSize) {
        store.dispatch(setErrors([intl.formatMessage({ id: 'selectCompanies.upload.fileTooLarge' })]))

        return {
          code: 'fileTooLarge',
          message: intl.formatMessage({ id: 'selectCompanies.upload.fileTooLarge' })
        }
      }

      return null
    }
  })

  useEffect(() => {
    async function getStats (): Promise<void> {
      const { fileLink, error } = await apiGet('getRegCodeExampleFileLink')

      if (error !== undefined) return

      setSampleFile(fileLink)
    }

    getStats().catch(console.error)
  }, [])

  function onCodesInputChange (event: ChangeEvent<HTMLTextAreaElement>): void {
    setCodesInput(event.target.value)
  }

  async function onAddFile (files: any[]): Promise<void> {
    if (files.length === 0) return

    setUploading(true)

    const fileForm = new FormData()
    fileForm.append('file', files[0])

    const result = await apiPostForm('checkRegCodesFile', { body: fileForm })

    await resetDb()
    await db.codesAccepted.bulkAdd(result.regCodesAccepted)
    await db.codesIncompleteData.bulkAdd(result.regCodesIncompleteData)
    await db.codesNotExist.bulkAdd(result.regCodesNotExist)

    setCodesAccepted(result.regCodesAccepted.length)
    setCodesIncompleteData(result.regCodesIncompleteData.length)
    setCodesNotExist(result.regCodesNotExist.length)
    setCodesUploaded(true)

    setUploading(false)
  }

  async function onAddCodes (): Promise<void> {
    if (codesInput === '') return

    setUploading(true)

    const codesArray = codesInput.trim().split(/[, \n]+/).map(x => x.trim())
    const result = await apiPost('checkRegCodes', {
      body: {
        regCodes: codesArray
      }
    })

    await resetDb()
    await db.codesAccepted.bulkAdd(result.regCodesAccepted)
    await db.codesIncompleteData.bulkAdd(result.regCodesIncompleteData)
    await db.codesNotExist.bulkAdd(result.regCodesNotExist)

    setCodesAccepted(result.regCodesAcceptedCount)
    setCodesIncompleteData(result.regCodesIncompleteDataCount)
    setCodesNotExist(result.regCodesNotExistCount)
    setCodesUploaded(true)

    setUploading(false)
  }

  return (
    <div>
      {uploading
        ? (
          <div className="pt-20 flex flex-col items-center gap-8 font-bold">
            <div className="animate-spin text-green-brand">
              <svg
                fill="none"
                height="186"
                viewBox="0 0 185 186"
                width="185"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.865 10.4448C30.2226 21.6635 16.9012 38.5281 10.0727 58.2887C3.24425 78.0494 3.31152 99.5405 10.2636 119.258C17.2156 138.976 30.6424 155.756 48.3546 166.864C66.0668 177.972 87.0197 182.752 107.797 180.425L97.5 88.5L47.865 10.4448Z"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="10"
                />

                <circle
                  cx="97"
                  cy="88"
                  fill="white"
                  r="88"
                />
              </svg>
            </div>

            <FormattedMessage id="selectCompanies.upload.loading" />
          </div>

          )
        : (
          <div className="p-9 bg-silver">
            <div className="flex flex-col md:flex-row gap-7">
              <div className="grow">
                <FormattedMessage id="selectCompanies.upload.fileInfo" />

                <div className="mt-2 font-bold text-teal-brand">
                  <a href={sampleFile}>
                    <FormattedMessage id="selectCompanies.upload.downloadSample" />
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full mt-9 flex flex-col md:flex-row gap-10">
              <div
                {...getRootProps()}
                className="p-8 grow w-full flex flex-col items-center justify-center gap-7 font-bold text-center bg-grey-light cursor-pointer"
              >
                <input {...getInputProps()} />

                {codesInput.trim() === ''
                  ? (
                    <>
                      <svg
                        fill="none"
                        height="113"
                        viewBox="0 0 113 113"
                        width="113"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M47.3177 69.3535C46.4373 68.9734 45.931 68.0422 46.0906 67.0972V34.6147V33.2004H36.1197H34.9658C33.1882 33.0235 32.782 32.2337 33.8743 30.8607C40.3517 22.6891 46.8391 14.5372 53.3694 6.43797C55.5008 3.80743 56.4888 3.90101 58.7552 6.43797C65.4719 14.2774 72.1819 22.1204 78.8834 29.9676L78.8841 29.9683C79.2562 30.5435 79.5499 31.1657 79.7573 31.8192C79.1191 32.0919 78.4482 32.2805 77.7607 32.3806C74.4753 32.4429 71.1897 32.3806 67.9036 32.3806H66.6772V33.5243V67.1283C66.8215 68.0566 66.2841 68.9559 65.3985 69.2699H47.3177L47.3177 69.3535Z"
                          fill="#666666"
                        />

                        <path
                          d="M4.56333 70.798H18.9119V94.4204H94.0218V70.8072H108.412C108.412 71.1713 108.474 71.5245 108.474 71.8473V100.784C108.474 105.546 105.469 108.436 100.562 108.436H12.3194C8.45157 108.477 5.72746 106.585 4.79114 103.279C4.59169 102.543 4.49377 101.785 4.50031 101.022V71.6813C4.50031 71.4209 4.50031 71.1511 4.56268 70.7972L4.56333 70.798Z"
                          fill="#666666"
                        />
                      </svg>

                      <FormattedMessage id="selectCompanies.upload.filePlaceholder" />
                    </>
                    )
                  : null}

                {codesInput.trim() !== ''
                  ? (
                    <>
                      <svg
                        fill="none"
                        height="112"
                        viewBox="0 0 112 112"
                        width="112"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          fill="#666666"
                          height="134"
                          transform="rotate(-45 4 13.1924)"
                          width="13"
                          x="4"
                          y="13.1924"
                        />

                        <rect
                          fill="#666666"
                          height="134"
                          transform="rotate(45 98.7523 4)"
                          width="13"
                          x="98.7523"
                          y="4"
                        />
                      </svg>

                      <FormattedMessage id="selectCompanies.upload.fileDisabled" />
                    </>
                    )
                  : null}
              </div>

              <div className="grow-0 self-center text-center">
                <FormattedMessage id="selectCompanies.upload.or" />
              </div>

              <div className="grow w-full">
                <FormattedMessage id="selectCompanies.upload.addByCode" />

                <Textarea
                  isResizable={false}
                  onChange={onCodesInputChange}
                  placeholder={intl.formatMessage({ id: 'selectCompanies.upload.addByCodePlaceholder' })}
                  rows={10}
                  value={codesInput}
                />

                <div className="mt-2 float-right">
                  <Button
                    disabled={codesInput.trim() === ''}
                    onClick={onAddCodes}
                  >
                    <FormattedMessage id='selectCompanies.upload.btnAdd' />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          )}
    </div>
  )
}
