export const defaultFontWeights = {
	thin: 100,
	extraLight: 200,
	light: 300,
	normal: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
	extraBold: 800,
	black: 900,
} as const;

export type FontWeights = typeof defaultFontWeights;

export type FontWeight = keyof FontWeights;
