import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Button, Text } from '@creditinfo-ui/atoms'

import { apiGet } from '../hooks/useApi'
import { useSessionStorage } from '../hooks/useStorage'
import Layout from '../components/Layout'
import Icon from '../components/Icon'
import { formatDateTimeToLocale } from '../helpers'

export default function Archive (): JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  const user = useSelector((state: any) => state.user)
  const [, setActivePage] = useSessionStorage('activePage', '/')
  const [showOrders, setShowOrders] = useState<number[]>([])
  const [orders, setOrders] = useState<ArchiveFile[] | null>(null)

  useEffect(() => {
    setActivePage('/archive')
  }, [])

  useEffect(() => {
    async function getOrders (): Promise<void> {
      const { orders, error } = await apiGet('getOrders')

      if (error !== undefined) return

      if (orders.length > 0) setShowOrders([0])

      setOrders(orders)
    }

    if (user === null) {
      navigate(`/sign-in?next=${location.pathname}`)
    } else {
      getOrders().catch(console.error)
    }
  }, [intl.locale, user])

  function getTitle (type: string): string {
    switch (type) {
      case 'ownSample':
        return intl.formatMessage({ id: 'archive.ownSample.title' })
      case 'targetList':
        return intl.formatMessage({ id: 'archive.selectSample.title' })
      case 'globalTargetList':
        return intl.formatMessage({ id: 'archive.globalSample.title' })
      default:
        return type
    }
  }

  function onToggleShow (index: number): void {
    if (showOrders.includes(index)) {
      setShowOrders(showOrders.filter(x => x !== index))
    } else {
      setShowOrders([...showOrders, index])
    }
  }

  function downloadfile (fileLink: string): void {
    window.open(fileLink, '_blank')
  }

  return (
    <Layout>
      <div className="container my-14 mx-auto xl:px-64 place-self-start">
        <div className="mb-8 md:mb-14">
          <Text
            message={{ id: 'archive.title' }}
            variant="viewTitle"
          />
        </div>

        {orders !== null && orders?.length === 0
          ? (
            <FormattedMessage id="archive.noOrders" />
            )
          : null}

        {orders !== null && orders?.length > 0
          ? orders.map((order, idx) => (
            <>
              <div
                className="w-full mt-8 py-4 px-6 flex items-center gap-2 font-bold uppercase text-red-brand border-t-red-brand border-t-[3px] border-b border-b-grey-light cursor-pointer"
                key={order.fileLink}
                onClick={function () { onToggleShow(idx) }}
              >
                <Icon type={showOrders.includes(idx) ? 'chevron_down' : 'chevron_up'} />

                {`${getTitle(order.type)} ${formatDateTimeToLocale(new Date(order.time), intl.locale)}`}
              </div>

              {showOrders.includes(idx)
                ? (
                  <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    {order.type !== 'globalTargetList'
                      ? (
                        <div className="font-bold md:col-span-2">
                          <FormattedMessage
                            id="archive.companyCount"
                            values={{ count: order.companiesCount }}
                          />
                        </div>
                        )
                      : null}

                    {order.orderedFilters !== undefined
                      ? (
                        <div>
                          <div className="font-bold">
                            <FormattedMessage id="archive.appliedFilters" />
                          </div>

                          <ul className="list-disc ml-6">
                            {order.orderedFilters.map(filter => (
                              <li key={filter.filter}>
                                {`${filter.filter}: ${filter.filterValues.join(', ')}`}
                              </li>
                            ))}
                          </ul>
                        </div>
                        )
                      : null}

                    <div>
                      <div className="font-bold">
                        <FormattedMessage id="archive.selectedFields" />
                      </div>

                      <ul className="list-disc ml-6">
                        {order.orderedDataFields.map(field => (
                          <li key={field.category}>
                            {`${field.category}: ${field.selectedDataFields.join(', ')}`}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {order.type !== 'globalTargetList'
                      ? (
                        <div className="md:col-span-2">
                          <Button
                            isDisabled={order.fileLink === null}
                            onClick={function () { downloadfile(order.fileLink) }}
                          >
                            <FormattedMessage id="archive.download" />
                          </Button>
                        </div>
                        )
                      : null}
                  </div>
                  )
                : null}
            </>
          ))
          : null}
      </div>
    </Layout>
  )
}
