import { TPlugin, createRenderer as createFelaRenderer } from 'fela';

import felaPluginBidi from 'fela-plugin-bidi';
import felaSortMediaQueryMobileFirst from 'fela-sort-media-query-mobile-first';
import felaPluginPlaceholderPrefixer from 'fela-plugin-placeholder-prefixer';
import felaEnforceLonghands from 'fela-enforce-longhands';
import felaPluginEmbedded from 'fela-plugin-embedded';
import felaPluginPrefixer from 'fela-plugin-prefixer';
import felaPluginFallbackValue from 'fela-plugin-fallback-value';
import felaPluginUnit from 'fela-plugin-unit';

import { makeResponsiveValuePlugin } from './plugins/responsiveValues';
import { makeTypedSelectorsPlugin } from './plugins/typedSelectors';

export interface CreateRendererParams {
	selectorPrefix?: string;
}

export const createRenderer = ({ selectorPrefix = '_' }: CreateRendererParams = {}) =>
	createFelaRenderer({
		devMode: process.env.NODE_ENV === 'development',
		enhancers: [felaEnforceLonghands(), felaSortMediaQueryMobileFirst()],
		plugins: [
			makeTypedSelectorsPlugin() as TPlugin,
			makeResponsiveValuePlugin() as TPlugin,
			felaPluginEmbedded(),
			felaPluginPlaceholderPrefixer(),
			felaPluginBidi('ltr'),
			felaPluginUnit(),
			felaPluginFallbackValue(),
			felaPluginPrefixer(),
		],
		// NOTE: Using a selector prefix is necessary because Fela generates class names like
		// `x y z aa ab ac`, meaning possible conflicts with Bootstrap classes such as `btn`.
		selectorPrefix,
	});
