import { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'

import messagesEn from '../locales/en.json'
import messagesEt from '../locales/et.json'

interface Props {
  children?: ReactNode
}

interface ContextInterface {
  locale?: string
  selectLanguage?: (newLocale: string) => void
  allLanguages: string[]
}

const language = localStorage.getItem('language') ?? 'et'

const translation = {
  en: messagesEn,
  et: messagesEt
}

const formats: any = {
  date: {
    dayMonthYear: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  }
}

export default function Wrapper ({ children }: Props): JSX.Element {
  const [locale, setLocale] = useState(language)
  const [messages, setMessages] = useState(translation[language as keyof typeof translation])

  useEffect(() => {
    document.title = messages['app.title']
    document.documentElement.lang = locale
  }, [locale])

  const selectLanguage = useMemo(() => {
    return (newLocale: string): void => {
      setLocale(newLocale)
      setMessages(translation[newLocale as keyof typeof translation])
      localStorage.setItem('language', newLocale)
    }
  }, [locale])

  const contextValue = useMemo(() => {
    return { locale, selectLanguage, allLanguages: Object.keys(translation) }
  }, [locale, selectLanguage])

  return (
    <Context.Provider value={contextValue}>
      <IntlProvider
        defaultFormats={formats}
        locale={locale}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export const Context = createContext<ContextInterface>({} as any)
