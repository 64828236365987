import { useEffect, useState } from 'react'
import { DateInput } from '@creditinfo-ui/dates'

interface Props {
  value: string[]
  onChange: Function
}

export default function FilterDateInterval ({ value, onChange }: Props): JSX.Element {
  const [firstUpdate, setFirstUpdate] = useState(true)
  const [minValue, setMinValue] = useState(value[0] ?? '')
  const [maxValue, setMaxValue] = useState(value[1] ?? '')

  const separator = '-'

  useEffect(() => {
    if (firstUpdate) {
      setFirstUpdate(false)
    } else {
      if (minValue === '' && maxValue === '') {
        onChange([])
      } else if (minValue !== '' && maxValue !== '' && minValue > maxValue) {
        setMinValue(maxValue)
        setMaxValue(minValue)
        onChange([maxValue, minValue, null])
      } else {
        onChange([minValue, maxValue, null])
      }
    }
  }, [minValue, maxValue])

  return (
    <div className="w-full lg:w-2/3 xl:w-1/2 flex items-center justify-start gap-6">
      <DateInput
        datePickerProps={{
          displayFormat: 'DD.MM.YYYY',
          placeholder: 'DD.MM.YYYY'
        }}
        onChange={function (e) { setMinValue(e ?? '') }}
        value={value[0]}
      />

      {separator}

      <DateInput
        datePickerProps={{
          displayFormat: 'DD.MM.YYYY',
          placeholder: 'DD.MM.YYYY'
        }}
        onChange={function (e) { setMaxValue(e ?? '') }}
        value={value[1]}
      />
    </div>
  )
}
