import { cx } from 'ramda-extension';
import { TextareaHTMLAttributes, forwardRef } from 'react';

import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';

import { TextInputStyleProps, textInputStyle } from './TextInput';

// NOTE: The textarea must be usable in a lot of contexts, including integration with other
// libraries which might depend on passing `disabled` and `readOnly` down.
interface CompatibilityTextareaProps {
	/** @deprecated Use `isDisabled` instead. */
	disabled?: boolean;
	/** @deprecated Use `isReadOnly` instead. */
	readOnly?: boolean;
}

export interface TextareaProps
	extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'disabled' | 'readOnly'>,
		CompatibilityTextareaProps {
	customStyle?: Style<TextareaStyleProps>;
	isDisabled?: boolean;
	isInvalid?: boolean;
	isReadOnly?: boolean;
	isResizable?: boolean;
}

export interface TextareaStyleProps extends TextInputStyleProps {
	isResizable: boolean;
}

export const textareaStyle = prepareStyle<TextareaStyleProps>((utils, { isResizable }) => ({
	height: 'auto',
	resize: isResizable ? 'vertical' : 'none',
}));

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	(props: TextareaProps, ref) => {
		const {
			className,
			customStyle,
			disabled: disabledProp,
			isDisabled: isDisabledProp,
			isInvalid = false,
			isReadOnly: isReadOnlyProp,
			isResizable = true,
			readOnly: readOnlyProp,
			...otherProps
		} = props;

		// NOTE: We must manually destructure all props so they are not passed through to the textarea.
		const isReadOnly = isReadOnlyProp ?? readOnlyProp ?? false;
		const isDisabled = isDisabledProp ?? disabledProp ?? false;

		const { applyStyle } = useStyles();

		return (
			<textarea
				className={cx(
					applyStyle([textInputStyle, textareaStyle, customStyle], {
						hasFocusedStyle: true,
						isDisabled,
						isFocused: false,
						isInvalid,
						isReadOnly,
						variant: 'form',
						isResizable,
					}),
					className
				)}
				disabled={isDisabled}
				readOnly={isReadOnly}
				ref={ref}
				{...otherProps}
			/>
		);
	}
);

Textarea.displayName = 'Textarea';
