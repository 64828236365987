import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Text } from '@creditinfo-ui/atoms'

interface Props {
  icon: JSX.Element
  titleMessageId: string
  textMessageId: string
  buttonMessageId: string
  url: string
  sectionClassName?: string
}

export default function HomeSection ({ titleMessageId, icon, textMessageId, buttonMessageId, url, sectionClassName }: Props): JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <div className={`mb-16 md:mb-0 flex flex-col items-center ${sectionClassName ?? ''}`}>
      <div className="h-32 mb-14 text-red-brand">
        {icon}
      </div>

      <div className="lg:min-h-[9rem]">
        <Text
          align="center"
          message={{ id: titleMessageId }}
          variant="extraTitle"
        />
      </div>

      <ul className="list-disc grow mt-6 mb-11 px-12 pt-6 border-t border-gray-other">
        {intl.formatMessage({ id: textMessageId }).split('\n').map(x => ((
          <li key={x}>
            { x }
          </li>
        )))}
      </ul>

      <div className="mb-6">
        <Button
          onClick={function () { navigate(url) }}
          type="button"
        >
          <FormattedMessage id={buttonMessageId} />
        </Button>
      </div>
    </div>
  )
}
