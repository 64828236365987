import { useContext, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Context } from './Wrapper'

function LanguageHandler (): null {
  const location = useLocation()
  const navigate = useNavigate()
  const { selectLanguage, allLanguages } = useContext(Context)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const language = searchParams.get('lang')
    const queryParams = new URLSearchParams(location.search)

    if (language && allLanguages.includes(language) && queryParams.has('lang')) {
      selectLanguage?.(language)
      document.getElementById('CookieConsent')?.setAttribute('data-culture', language)
      window.CookieInformation.loadConsent()
      localStorage.setItem('language', language)
      queryParams.delete('lang')

      const newSearchString = queryParams.toString()
      navigate(`${location.pathname}${newSearchString ? '?' + newSearchString : ''}`, { replace: true })
    }
  }, [searchParams])

  // useEffect(() => {
  //   const language = searchParams.get('lang')
  //
  //   if (!language) {
  //     const { defaultLocale } = intl
  //     const presetLang = localStorage.getItem('language')
  //     const defaultLang = presetLang ?? defaultLocale
  //     selectLanguage?.(defaultLang)
  //     document.getElementById('CookieConsent')?.setAttribute('data-culture', defaultLang)
  //     window.CookieInformation.loadConsent()
  //     localStorage.setItem('language', defaultLang)
  //     searchParams.set('lang', defaultLang)
  //     setSearchParams(searchParams)
  //   }
  // }, [location.pathname])

  return null
}

export default LanguageHandler
