import { Line } from 'react-chartjs-2'
import {
  defaults,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Filler
} from 'chart.js'

import { useEffect, useState } from 'react'

interface Props {
  chartData: any
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Filler
)

export default function BarChart ({ chartData }: Props): JSX.Element {
  const [legendPosition, setLegendPosition] = useState<'right' | 'bottom'>('right')

  useEffect(() => {
    defaults.font.family = 'Open Sans'
    const handleResize = (): void => {
      if (window.innerWidth < 1024) {
        setLegendPosition('bottom')
      } else {
        setLegendPosition('right')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const options = {
    responsive: true,
    stepped: true,
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: (value: string | number) => {
            return `${value}%`
          }
        }
      }
    },
    plugins: {
      legend: {
        position: legendPosition,
        labels: {
          padding: legendPosition === 'bottom' ? 0 : 25
        }
      },
      tooltip: {
        enabled: false
      }
    }
  }

  return (
    <Line
      data={chartData}
      options={options}
    />
  )
}
