import { Radio } from '@creditinfo-ui/atoms'

interface Props {
  value: string[]
  onChange: Function
  options: FilterOption[]
}

export default function FilterRadio ({ value, onChange, options }: Props): JSX.Element {
  return (
    <div className="flex flex-col justify-start gap-6">
      {options.map(option => (
        <Radio
          isChecked={value.includes(option.optionCode)}
          key={option.optionCode}
          label={option.optionText}
          onChange={function (e) { if (e.target.checked) onChange([option.optionCode]) }}
        />
      ))}
    </div>
  )
}
