export const defaultSizes = {
	control: {
		medium: '4.2rem',
		small: '2.5rem',
	},
	navigation: {
		collapsed: '70px',
		expanded: '200px',
	},
	// TODO: This shouldn't be in the CreditinfoUI repository.
	pdfHeader: {
		icon: '3.2rem',
		logo: '3rem',
	},
} as const;

export type Sizes = typeof defaultSizes;
