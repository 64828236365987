import { ChangeEventHandler, FocusEventHandler, ForwardedRef, ReactNode, forwardRef } from 'react';
import type * as CSS from 'csstype';
import { useGeneratedId } from '@creditinfo-ui/utils';
import { Style, StyleObject, prepareStyle, useStyles } from '@creditinfo-ui/styles';

export type CheckboxVariant = 'checkMark' | 'primaryFill';

export const CHECKBOX_SIZE = '2rem';

const getCheckMarkBackgroundImage = (color: CSS.Property.Color) =>
	`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 427 427'%3E%3Cpath fill='${color.replace(
		'#',
		'%23'
	)}' d='M366.933,42.667l-217.6,219.733l-89.6,-89.6l-59.733,59.733l149.333,151.467l277.334,-279.467l-59.734,-61.866Z' /%3E%3C/svg%3E")`;

interface InputStyleProps {
	isDisabled: boolean;
	variant: CheckboxVariant;
}

const inputStyle = prepareStyle<InputStyleProps>((utils, { isDisabled, variant }) => {
	const styleObject: StyleObject = {
		height: 'auto',
		opacity: 0,
		position: 'absolute',
		zIndex: -1,

		extend: [
			{
				condition: variant === 'checkMark',
				style: {
					selectors: {
						':checked + label::after': {
							backgroundImage: getCheckMarkBackgroundImage(
								isDisabled ? utils.colors.gray400 : utils.colors.gray800
							),
							backgroundPosition: '50%',
							backgroundRepeat: 'no-repeat',
							backgroundSize: '60% 60%',
						},
					},
				},
			},
			{
				condition: variant === 'primaryFill',
				style: {
					selectors: {
						':checked + label::before': {
							backgroundColor: isDisabled ? utils.colors.gray400 : utils.colors.primary,
						},
					},
				},
			},
			{
				condition: !isDisabled,
				style: {
					selectors: {
						':checked + label::before, :focus + label::before, :active + label::before': {
							borderColor: utils.colors.gray800,
						},
						':focus + label::before': {
							boxShadow: utils.boxShadows.focusDrop(
								utils.transparentize(0.7, utils.colors.primary)
							),
							outline: 0,
						},
					},
				},
			},
		],
	};

	return styleObject;
});

interface LabelStyleProps {
	isDisabled: boolean;
}

const labelStyle = prepareStyle<LabelStyleProps>((utils, { isDisabled }) => {
	const boxStyle: StyleObject = {
		borderRadius: utils.borders.radii.basic,
		content: '""',
		display: 'block',
		height: CHECKBOX_SIZE,
		insetInlineStart: 0,
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: CHECKBOX_SIZE,
	};

	return {
		color: isDisabled ? utils.colors.gray400 : utils.colors.gray800,
		cursor: isDisabled ? 'not-allowed' : 'pointer',
		display: 'block',
		fontSize: utils.fontSizes.base,
		fontWeight: utils.fontWeights.semiBold,
		margin: 0,
		minHeight: CHECKBOX_SIZE,
		paddingInlineStart: utils.sum([CHECKBOX_SIZE, utils.spacings.sm]),
		position: 'relative',
		selectors: {
			'::before': {
				...boxStyle,
				backgroundColor: isDisabled ? 'transparent' : utils.colors.white,
				borderColor: utils.colors.gray300,
				borderStyle: 'solid',
				borderWidth: utils.borders.widths.md,
				boxShadow: 'none',
				transitionDuration: utils.transitions.speeds.default,
				transitionTimingFunction: utils.transitions.easing,
			},
			'::after': boxStyle,
		},
		extend: {
			condition: !isDisabled,
			style: {
				selectors: {
					':hover::before': {
						borderColor: utils.colors.gray800,
					},
				},
			},
		},
	};
});

export interface CheckboxProps {
	customStyle?: Style<LabelStyleProps>;
	id?: string;
	isChecked?: boolean;
	isDisabled?: boolean;
	label?: ReactNode;
	name?: string;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	value?: string;
	variant?: CheckboxVariant;
}

export const Checkbox = forwardRef(
	(
		{
			customStyle,
			id: idProp,
			isChecked,
			isDisabled = false,
			label,
			name,
			onBlur,
			onChange,
			onFocus,
			value,
			variant = 'checkMark',
		}: CheckboxProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const { applyStyle } = useStyles();
		const generatedId = useGeneratedId();
		const inputId = idProp ?? `Checkbox_input_${generatedId}`;

		return (
			<>
				<input
					checked={isChecked}
					className={applyStyle(inputStyle, { isDisabled, variant })}
					disabled={isDisabled}
					id={inputId}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					onFocus={onFocus}
					ref={ref}
					type="checkbox"
					value={value}
				/>
				<label className={applyStyle([labelStyle, customStyle], { isDisabled })} htmlFor={inputId}>
					{label ?? null}
				</label>
			</>
		);
	}
);
