// NOTE: `zIndex` values are defined as lower boundaries of a size 10 interval. For example,
// modals should have `zIndex` in interval [1040; 1050).

// WARNING: Remember that these values need to comply with Bootstrap indices, which have been mostly
// copied over here.
export const defaultZIndices = {
	// NOTE: Remember that modals create their own stacking context, meaning that menus in modals
	// will work just fine, as they don't rely on portals.
	menu: 1000,
	// TODO: Remove in favour of the `menu` z-index.
	datePicker: 1010,
	sticky: 1020,
	fixed: 1030,
	modal: 1040,
	// HACK: Although from a UX/UI perspective it would probably be better if toasts always covered
	// everything, we are using 1050 instead of 1080 because of announcement modals which must cover
	// toasts. These modals have their `zIndex` set to `toast + 1`, meaning that if we wanted to use
	// tooltips or popovers inside these modals, they wouldn't work (they use portals).
	toast: 1050,
	popover: 1060,
	tooltip: 1070,
	// WARNING: Here be dragons, don't add any more values below tooltips.
} as const;

export type ZIndex = keyof typeof defaultZIndices;

export type ZIndices = typeof defaultZIndices;
