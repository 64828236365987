import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { setUser } from '../store/user'
import { apiGet, apiPost } from '../hooks/useApi'
import { db } from '../hooks/useDexieDB'
import { useSessionStorage } from '../hooks/useStorage'
import { isEmail, isRegCode } from '../hooks/useValidation'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import SelectCompanies from '../components/Steps/SelectCompanies'
import SelectData from '../components/Steps/SelectData'
import Order from '../components/Steps/Order'
import OrderForm from '../components/Steps/OrderForm'

export default function OwnSample (): JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tabs = [
    intl.formatMessage({ id: 'ownSample.step.selecFilters' }),
    intl.formatMessage({ id: 'ownSample.step.selectData' }),
    intl.formatMessage({ id: 'ownSample.step.order' })
  ]

  const steps = [
    'sampling',
    'selection',
    'order',
    'orderForm',
    'orderPayment'
  ]

  const user = useSelector((state: any) => state.user)
  const [statsLoading, setStatsLoading] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [activePage, setActivePage] = useSessionStorage('activePage', '/')
  const [activeStep, setActiveStep] = useSessionStorage('activeStep', steps[0])
  const [codesUploaded, setCodesUploaded] = useSessionStorage('ownSample.codesUploaded', false)
  const [codesAccepted, setCodesAccepted] = useSessionStorage('ownSample.codesAccepted', 0)
  const [codesNotExist, setCodesNotExist] = useSessionStorage('ownSample.codesNotExist', 0)
  const [codesIncompleteData, setCodesIncompleteData] = useSessionStorage('ownSample.codesIncompleteData', 0)
  const [selectedDataFields, setSelectedDataFields] = useSessionStorage<DataField[]>('ownSample.selectedDataFields', [])
  const [quartersIncluded, setQuartersIncluded] = useSessionStorage('ownSample.quartersIncluded', 1)
  const [stats, setStats] = useSessionStorage<Stats>('ownSample.stats', {
    dataFieldsCount: null,
    companiesCount: null,
    sum: null,
    sumWithVat: null
  })
  const [fileFormat, setFileFormat] = useSessionStorage('ownSample.fileFormat', 'xlsx')
  const [formData, setFormData] = useSessionStorage<OrderFormData>('ownSample.formData', {
    isPrivateCustomer: false,
    email: '',
    regCode: '',
    info: '',
    acceptTerms: false
  })

  const isNextDisabled = useMemo(() => {
    if (statsLoading) return true

    switch (activeStep) {
      case 'sampling':
        return stats.companiesCount === null || stats.companiesCount === 0 || codesAccepted === 0
      case 'selection':
        return selectedDataFields.length === 0
      case 'order':
        return false
      case 'orderForm':
        return !formData.acceptTerms || !isEmail(formData.email) || (!formData.isPrivateCustomer && !isRegCode(formData.regCode))
      default:
        return true
    }
  }, [statsLoading, stats, activeStep, codesAccepted, selectedDataFields, formData])

  const nextButtonText = useMemo(() => {
    if (activeStep === 'order' && user !== null) return intl.formatMessage({ id: 'footer.next.confirm' })
    if (activeStep === 'orderForm') return intl.formatMessage({ id: 'footer.next.confirm' })
    if (activeStep === 'orderPayment') return intl.formatMessage({ id: 'footer.next.confirm' })

    return intl.formatMessage({ id: 'footer.next.next' })
  }, [intl.locale, user, activeStep])

  useEffect(() => {
    setActivePage('/own-sample')
  }, [])

  useEffect(() => {
    async function getUser (): Promise<void> {
      const { error } = await apiGet('authGetUser')

      if (error === undefined) return

      dispatch(setUser(null))
    }

    getUser().catch(console.error)
  }, [activePage, activeStep])

  useEffect(() => {
    const targetDiv = document.getElementById('main-wrapper')
    if (targetDiv) {
      targetDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [activeStep])

  useEffect(() => {
    async function getStats (): Promise<void> {
      setStatsLoading(true)

      const regCodes = await db.codesAccepted.toCollection().primaryKeys()
      const { dataFieldsCount, companiesCount, sum, sumWithVat, error } = await apiPost('getQueryStat', {
        body: {
          fileFormat,
          regCodes,
          selectedDataFields: selectedDataFields.length > 0
            ? selectedDataFields.map(x => ({
              dataFieldName: x.dataFieldName,
              quartersIncluded: x.quartersIncluded
            }))
            : null
        }
      })

      if (codesUploaded) {
        setStats({
          dataFieldsCount: null,
          companiesCount: 0,
          sum: null,
          sumWithVat: null
        })
      }

      setStatsLoading(false)

      if (error !== undefined) return

      setStats({
        dataFieldsCount: dataFieldsCount ?? null,
        companiesCount: companiesCount ?? null,
        sum: sum ?? null,
        sumWithVat: sumWithVat ?? null
      })
    }

    getStats().catch(console.error)
  }, [codesAccepted, selectedDataFields])

  useEffect(() => {
    if (codesUploaded) {
      setStats({
        dataFieldsCount: null,
        companiesCount: 0,
        sum: null
      })
    } else {
      setStats({
        dataFieldsCount: null,
        companiesCount: null,
        sum: null
      })
    }
  }, [codesUploaded])

  function onPreviousStep (): any {
    const index = steps.findIndex(x => x === activeStep) - 1

    if (index < 0) navigate('/')

    setActiveStep(steps[index])
  }

  async function onNextStep (): Promise<void> {
    let index = steps.findIndex(x => x === activeStep) + 1

    if (index > steps.length - 1) index = steps.length - 1

    if (activeStep === 'order' && user !== null) {
      await onOrder()
    } else {
      setActiveStep(steps[index])
    }
  }

  async function onOrder (): Promise<void> {
    setStatsLoading(true)

    const regCodes = await db.codesAccepted.toCollection().primaryKeys()
    const { error } = await apiPost('postMAQuery', {
      body: {
        regCodes: regCodes.length > 0 ? regCodes : null,
        selectedDataFields: selectedDataFields.length > 0
          ? selectedDataFields.map(x => ({
            dataFieldName: x.dataFieldName,
            quartersIncluded: x.quartersIncluded
          }))
          : null,
        fileFormat
      }
    })

    setStatsLoading(false)

    if (error !== undefined) return

    navigate('/order-success')
  }

  async function onPayment (paymentMethodCode: string): Promise<void> {
    if (paymentLoading) return

    setStatsLoading(true)
    setPaymentLoading(true)

    const regCodes = await db.codesAccepted.toCollection().primaryKeys()
    const { gatewayLink, error } = await apiPost('preparePayment', {
      body: {
        regCodes: regCodes.length > 0 ? regCodes : null,
        selectedDataFields: selectedDataFields.length > 0
          ? selectedDataFields.map(x => ({
            dataFieldName: x.dataFieldName,
            quartersIncluded: x.quartersIncluded
          }))
          : null,
        fileFormat,
        email: formData.email,
        regCode: parseInt(formData.regCode ?? ''),
        info: formData.info,
        paymentMethodCode
      }
    })

    setStatsLoading(false)
    setPaymentLoading(false)

    if (error !== undefined) return

    if (gatewayLink !== undefined) location.href = gatewayLink
  }

  const layoutFooter = (
    <Footer
      fieldsCountSectionClassName="hidden md:block"
      filterCountSectionClassName="hidden md:block"
      isNextDisabled={isNextDisabled}
      isStatsLoading={statsLoading}
      nextButtonText={nextButtonText}
      onNextStep={onNextStep}
      onPreviousStep={onPreviousStep}
      stats={stats}
    />
  )

  return (
    <Layout footer={layoutFooter}>
      {{
        sampling: (
          <SelectCompanies
            codesAccepted={codesAccepted}
            codesIncompleteData={codesIncompleteData}
            codesNotExist={codesNotExist}
            codesUploaded={codesUploaded}
            setCodesAccepted={function (count: number) { setCodesAccepted(count) }}
            setCodesIncompleteData={function (count: number) { setCodesIncompleteData(count) }}
            setCodesNotExist={function (count: number) { setCodesNotExist(count) }}
            setCodesUploaded={function (value: boolean) { setCodesUploaded(value) }}
            tabs={tabs}
          />
        ),
        selection: (
          <SelectData
            quartersIncluded={quartersIncluded}
            selectedDataFields={selectedDataFields}
            setQuartersIncluded={function (quarters: number) { setQuartersIncluded(quarters) }}
            setSelectedDataFields={function (fields: DataField[]) { setSelectedDataFields(fields) }}
            tabs={tabs}
          />
        ),
        order: (
          <Order
            fileFormat={fileFormat}
            selectedDataFields={selectedDataFields}
            setFileFormat={setFileFormat}
            stats={stats}
            tabs={tabs}
          />
        ),
        orderForm: (
          <OrderForm
            formData={formData}
            setFormData={function (data: OrderFormData) { setFormData(data) }}
            stats={stats}
            tabs={tabs}
          />
        ),
        orderPayment: (
          <OrderForm
            formData={formData}
            onCancelPayment={function () { onPreviousStep() }}
            onPayment={async function (code: string) { await onPayment(code) }}
            payment
            setFormData={function (data: OrderFormData) { setFormData(data) }}
            stats={stats}
            tabs={tabs}
          />
        )
      }[activeStep]}
    </Layout>
  )
}
