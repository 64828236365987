import { Color, Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { useGeneratedId } from '@creditinfo-ui/utils';
import {
	ComponentType,
	HTMLAttributes,
	SVGProps,
	Suspense,
	forwardRef,
	lazy,
	useContext,
	useMemo,
} from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { cx } from 'ramda-extension';
import universalImport from 'universal-import.macro';
import {
	IconType,
	defaultColorsByIconType,
	horizontallyFlippableIconTypes,
	iconTypes,
} from './iconTypes';
import { m } from '../../messages';
import { TooltipContext } from '../Tooltip';

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const iconSizes = {
	xs: '1rem',
	sm: '1.4rem',
	md: '1.8rem',
	lg: '2.2rem',
	xl: '2.6rem',
	xxl: '3rem',
} as const;

export interface OurIconProps {
	color?: Color;
	customStyle?: Style<IconStyleProps>;
	/**
	 * Indicates that the icon has some accessible text beside it, disabling the default browser
	 * tooltip and setting `aria-hidden` to true.
	 */
	isLabeled?: boolean;
	size?: IconSize;
	title?: string;
	type: IconType;
}

export interface IconProps
	extends Omit<HTMLAttributes<HTMLSpanElement>, keyof OurIconProps>,
		OurIconProps {}

export interface IconStyleProps {
	color?: Color;
	size?: IconSize;
	type: IconType;
}

const iconStyle = prepareStyle<IconStyleProps>((utils, { color, size, type }) => ({
	color: color ? utils.colors[color] : 'inherit',
	// NOTE: `inline` should be the default for icons and `flex` improves line height handling.
	display: 'inline-flex',
	fill: 'currentcolor',
	fontSize: size ? iconSizes[size] : 'inherit',
	// NOTE: Line height only affects the missing icon fallback (when a single character is rendered
	// instead of an SVG), but only in some specific cases (e.g. inside table cells). In other cases,
	// it will not have any effect whatsoever.
	lineHeight: 1,
	transform:
		utils.isRtl && horizontallyFlippableIconTypes.includes(type) ? 'scaleX(-1)' : undefined,

	extend: {
		condition: iconTypes.includes(type),
		style: {
			// NOTE: For alignment of SVG with the baseline of inline text. The same approach is used
			// for example by Font Awesome and Bootstrap. See the following link for more information:
			// https://blog.getbootstrap.com/2021/02/22/bootstrap-icons-1-4-0/
			verticalAlign: '-0.125em',
		},
	},
}));

const suspenseFallbackStyle = prepareStyle(() => ({ width: '1em' }));

type AssetComponentType = ComponentType<
	SVGProps<SVGSVGElement> & {
		title?: string;
		titleId?: string;
	}
>;

const importAssetModule = (type: IconType): Promise<{ default: AssetComponentType }> =>
	// HACK: We are using a Babel macro so we can conditionally switch between `require` and `import`.
	// Webpack relies on static analysis to handle code splitting and Gatsby's SSR bundling process
	// throws errors on asynchronous dynamic imports: https://github.com/gatsbyjs/gatsby/issues/32262
	// Jest workers aren't particularly happy about these imports either.
	Promise.resolve(
		universalImport(
			`./assets/build/${type}`,
			'String(process.env.GATSBY_BUILD_STAGE).includes("html") || process.env.NODE_ENV === "test"'
		)
	);

const assetComponents: Partial<Record<IconType, AssetComponentType>> = {};
const SHOULD_PRELOAD_ASSETS = typeof window === 'undefined' || process.env.NODE_ENV === 'test';

export const loadIconAsset = async (type: IconType): Promise<{ default: AssetComponentType }> => {
	const assetModule = await importAssetModule(type);
	assetComponents[type] = assetModule.default;

	return assetModule;
};

// NOTE: Because lazy loading of components with `Suspense` is not supported during server-side
// rendering, we must preload the assets manually so our PDFs render correctly in the first render.
if (SHOULD_PRELOAD_ASSETS) {
	iconTypes.forEach(loadIconAsset);
}

const useAssetComponent = (type: IconType) =>
	useMemo(() => assetComponents[type] ?? lazy(() => loadIconAsset(type)), [type]);

const getMissingIconFallback = (title: string | undefined): string =>
	(title ? String(title)[0]?.toUpperCase() : undefined) ?? '#';

export const Icon = forwardRef<HTMLSpanElement, IconProps>(
	(
		{
			className: classNameProp,
			color,
			customStyle,
			isLabeled = false,
			size,
			title: titleProp,
			type,
			...otherProps
		}: IconProps,
		ref
	) => {
		const { applyStyle } = useStyles();
		const intl = useIntl();
		const hasParentTooltip = useContext(TooltipContext);
		const generatedId = useGeneratedId();

		const titleMessage: MessageDescriptor | undefined = m[`icon_${type}`];

		const title = useMemo(
			() => titleProp ?? (titleMessage ? intl.formatMessage(titleMessage) : undefined),
			[intl, titleMessage, titleProp]
		);

		const titleId = title ? `Icon__${type}__${generatedId}` : undefined;

		const className = cx(
			applyStyle([iconStyle, customStyle], {
				color: color ?? defaultColorsByIconType[type],
				size,
				type,
			}),
			classNameProp
		);

		const Asset = useAssetComponent(type);

		const outerElementProps = {
			'data-icon': type,
			className,
			ref,
			...(isLabeled && { 'aria-hidden': true }),
			...otherProps,
		};

		const innerElementProps = {
			role: 'img',
			...(hasParentTooltip || isLabeled ? { 'aria-label': title } : { title }),
		};

		if (!iconTypes.includes(type)) {
			return (
				<span {...outerElementProps}>
					<span {...innerElementProps}>{getMissingIconFallback(title)}</span>
				</span>
			);
		}

		const assetElement = (
			<Asset
				{...innerElementProps}
				titleId={titleId}
				// NOTE: `direction="ltr"` is necessary to correctly show the asterisk icon in RTL mode
				// because it uses the `<text>` SVG element.
				direction="ltr"
			/>
		);

		if (SHOULD_PRELOAD_ASSETS) {
			return <span {...outerElementProps}>{assetElement}</span>;
		}

		return (
			// NOTE: The SVG asset must be wrapped in a span so the ref is registered synchronously.
			// Otherwise it would not be possible to attach tooltips to icons.
			<span {...outerElementProps}>
				<Suspense
					fallback={<span {...innerElementProps} className={applyStyle(suspenseFallbackStyle)} />}
				>
					{assetElement}
				</Suspense>
			</span>
		);
	}
);

Icon.displayName = 'Icon';
