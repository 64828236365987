import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { TextInput } from '@creditinfo-ui/atoms'
import BarChart from '../BarChart'

interface Props {
  value: string[]
  onChange: Function
  min: number
  max: number
  diagram: FilterDiagram[]
  filterText?: string
  filterName?: string
}

const toThousandSeparators = (input: number | number[]): string | string[] => {
  const options = { minimumFractionDigits: 0, style: 'decimal', useGrouping: true }

  if (Array.isArray(input)) {
    return input.map(num => num.toLocaleString('en-US', options).replace(/,/g, ' '))
  } else {
    return input.toLocaleString('en-US', options).replace(/,/g, ' ')
  }
}

export default function FilterInterval ({ value, onChange, min, max, diagram, filterText, filterName }: Props): JSX.Element {
  const intl = useIntl()
  const isGlobalSamplePage = window.location.pathname.includes('global-sample')

  const [firstUpdate, setFirstUpdate] = useState(true)
  const [minValue, setMinValue] = useState(value[0] ?? '')
  const [maxValue, setMaxValue] = useState(value[1] ?? '')

  const separator = '-'

  useEffect(() => {
    if (firstUpdate) {
      setFirstUpdate(false)
    } else {
      const getData = setTimeout(() => {
        if (minValue === '' && maxValue === '') {
          onChange([])
        } else {
          onChange([minValue, maxValue, null])
        }
      }, 500)

      return () => clearTimeout(getData)
    }
  }, [minValue, maxValue])

  const chartData = useMemo(() => ({
    labels: [
      ...toThousandSeparators(diagram.map(x => Math.floor(x.scaleMin))),
      toThousandSeparators(Math.floor(diagram[diagram.length - 1].scaleMax))
    ],
    datasets: [{
      label: intl.formatMessage({ id: 'selectFilters.diagram.countName' }),
      data: [
        ...diagram.map(x => x.value),
        0
      ],
      borderColor: '#0C9CB2',
      backgroundColor: '#0C9CB2',
      fill: true,
      pointRadius: 0
    }]
  }), [diagram])

  function checkRange (): void {
    let tempMinValue = parseInt(minValue)
    let tempMaxValue = parseInt(maxValue)

    if (!isNaN(tempMinValue) && (tempMinValue < min || tempMinValue > max)) tempMinValue = NaN
    if (!isNaN(tempMaxValue) && (tempMaxValue < min || tempMaxValue > max)) tempMaxValue = NaN

    if (!isNaN(tempMinValue) && !isNaN(tempMaxValue) && tempMinValue > tempMaxValue) {
      setMinValue(tempMaxValue.toString())
      setMaxValue(tempMinValue.toString())
    } else {
      setMinValue(!isNaN(tempMinValue) ? tempMinValue.toString() : '')
      setMaxValue(!isNaN(tempMaxValue) ? tempMaxValue.toString() : '')
    }
  }

  const graphLabel = filterName === 'mta_employees' || filterName === 'employees' || filterName === 'quick_ratio' || filterName === 'operating_margin' || filterName === 'profit_margin' || filterName === 'debt_ratio' || filterName === 'immovables' ? filterText : `${filterText}, €`

  return (
    <>
      <div className="w-full lg:w-2/3 xl:w-1/2 flex items-center justify-start gap-6">
        <TextInput
          onBlur={function () { checkRange() }}
          onChange={function (e) { setMinValue(e.target.value.replace(/[^0-9.-]/g, '')) }}
          placeholder={!isGlobalSamplePage ? min.toString() : intl.formatMessage({ id: 'global.minimal' })}
          value={minValue}
        />

        {separator}

        <TextInput
          onBlur={function () { checkRange() }}
          onChange={function (e) { setMaxValue(e.target.value.replace(/[^0-9.-]/g, '')) }}
          placeholder={!isGlobalSamplePage ? max.toString() : intl.formatMessage({ id: 'global.maximal' })}
          value={maxValue}
        />
      </div>

      {diagram.length > 0 && !isGlobalSamplePage
        ? (
          <div className="mt-12 w-full lg:w-[58%] flex flex-col items-center">
            <BarChart chartData={chartData} />
            <span className="text-[12.3px] hidden lg:block mt-2.5 text-[#666666]">
              {graphLabel ?? ''}
            </span>
          </div>
          )
        : null}
    </>
  )
}
