import { StyleObject } from '@creditinfo-ui/fela';

export type ResponsiveFontSizeValue = StyleObject['fontSize'];

export const defaultFontSizes = {
	activeTabLabel: '2rem',
	badge: '1rem',
	base: '1.4rem',
	caption: '1.2rem',
	tabLabel: '1.5rem',
	sectionTitle: '1.6rem',
	tagline: '1.8rem',
	viewTitle: { xs: '3rem', md: '3.6rem' },
	extraTitle: { xs: '2.4rem', md: '3.2rem' },
	collapsibleTitle: '1.6rem',
	cardControlDetails: '1.4rem',
	cardControlLabel: '1.6rem',
	// TODO: This shouldn't be in the CreditinfoUI repository.
	pdfTitle: '2rem',
} as const;

export type FontSize = keyof typeof defaultFontSizes;

export type FontSizes = Record<Exclude<FontSize, 'base'>, ResponsiveFontSizeValue> & {
	base: string;
	cardControlLabel: string;
};
