import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Select, Text } from '@creditinfo-ui/atoms'

import Icon from './Icon'

interface Props {
  titleMessageId: string
  disableChildrenSelect?: boolean
  noSelectedCategoryForMobile?: boolean
  items: MenuItem[]
  intlOpenItemsIds?: number[]
  activeItemIds?: number[]
  onClick: Function
  selectedFilters?: FilterValue[]
}

export default function Menu ({ titleMessageId, items = [], activeItemIds = [], selectedFilters, disableChildrenSelect, noSelectedCategoryForMobile, onClick, intlOpenItemsIds = [] }: Props): JSX.Element {
  const intl = useIntl()

  const [activeItemId, setActiveItemId] = useState(-2)
  const [activeChildId, setActiveChildId] = useState(-2)
  const [openItemsIds, setOpenItemsIds] = useState<number[]>(intlOpenItemsIds)

  const openItemChilds = useMemo(() => items.find(x => x.id === activeItemId)?.childs ?? [], [intl.locale, items, activeItemId])

  const activeItemLabel = useMemo(() => items.find(x => x.id === activeItemId)?.label ?? '', [intl.locale, items, activeItemId])

  const activeChildLabel = useMemo(() => openItemChilds.find(x => x.id === activeChildId)?.label ?? '', [intl.locale, items, activeChildId])

  useEffect(() => {
    // Firstly show category selection in mobile view
    if (window.innerWidth < 1024 && noSelectedCategoryForMobile) {
      setActiveItemId(-2)

      return
    }

    const intlPageLoad = JSON.parse(localStorage.getItem('intlCompaniesTargetListPageLoad') ?? 'false') &&
      window.location.pathname.includes('select-sample') && selectedFilters && !selectedFilters.length

    if (items.length === 0) return

    if ((intlPageLoad === true || window.innerWidth < 1024) && !disableChildrenSelect) {
      onMenuClick(items[0], undefined, true)
      setActiveItemId(-2)

      return
    }

    onMenuClick(items[0], undefined, true)
  }, [items])

  function onMenuClick (item?: MenuItem, child?: MenuItem, isMobileScreen?: boolean): void {
    if (item === undefined) return

    setActiveItemId(item.id)

    if (child === undefined) {
      if (openItemsIds.includes(item.id) && !isMobileScreen) {
        setOpenItemsIds(openItemsIds.filter(x => x !== item.id))
      } else {
        setOpenItemsIds([...openItemsIds, item.id])
      }

      if (item.childs !== undefined && item.childs.length > 0) {
        child = item.childs[0]

        if (isMobileScreen) {
          setActiveChildId(-2)
          onClick(item, { id: -2 })

          return
        }

        setActiveChildId(child.id)
      }
    } else {
      setActiveChildId(child.id)
    }

    onClick(item, child)
  }

  function onMenuSelectChange (label: string): void {
    const item = items.find(x => x.label === label)

    onMenuClick(item, undefined, true)
  }

  function onMenuChildSelectChange (label: string): void {
    const item = items.find(x => x.id === openItemsIds[openItemsIds.length - 1])
    const child = openItemChilds.find(x => x.label === label)

    onMenuClick(item, child, true)
  }

  function isMenuActive (menu: MenuItem): boolean {
    if (menu.childs === undefined) {
      return activeItemIds.includes(menu.id)
    } else {
      return menu.childs.some(x => activeItemIds.includes(x.id))
    }
  }

  return (
    <>
      <div className="hidden lg:block fixed top-16 bottom-40 left-0 z-10 w-96 pt-32 pl-14 pb-8 pr-6 overflow-y-auto bg-silver">
        <Text
          message={{ id: titleMessageId }}
          variant="extraTitle"
        />

        <div className="mt-8">
          { items.map(item => (
            <div key={item.id}>
              <a
                className={`py-2 flex items-top gap-2 font-bold cursor-pointer ${item.id === activeItemId && 'text-red-brand'} ${isMenuActive(item) && 'text-red-hover'}`}
                onClick={function () { onMenuClick(item) }}
              >
                <div>
                  <div className="h-8 w-8 object-contain">
                    <Icon type={isMenuActive(item) ? 'checked' : item.icon ?? ''} />
                  </div>
                </div>

                <div className="grow whitespace-pre-line">
                  {item.label}
                </div>

                {item.childs !== undefined && (
                  !openItemsIds.includes(item.id)
                    ? (
                      <div className="h-8 w-8">
                        <Icon type="chevron_up" />
                      </div>
                      )
                    : (
                      <div className="h-8 w-8">
                        <Icon type="chevron_down" />
                      </div>
                      )
                )}
              </a>

              {item.childs !== undefined && openItemsIds.includes(item.id)
                ? (
                  <div className="ml-10 mb-6">
                    {item.childs.map((child: MenuItem) => (
                      <a
                        className={`${!openItemsIds.includes(item.id) && 'hidden'} my-1 flex items-top cursor-pointer ${item.id === activeItemId && child.id === activeChildId && 'font-bold text-red-brand'} ${isMenuActive(child) && 'text-red-hover'}`}
                        key={`${item.id}-${child.id}`}
                        onClick={function () { onMenuClick(item, child) }}
                      >
                        {child.label}
                      </a>
                    ))}
                  </div>
                  )
                : null}
            </div>
          )) }
        </div>
      </div>

      <div className="lg:hidden mb-8 flex flex-col gap-4">
        <Select
          isCombobox={false}
          items={items.map(x => x.label)}
          nilMessage={intl.formatMessage({ id: 'global.selectCategory' })}
          onChange={function (e) { onMenuSelectChange(e ?? '') }}
          sorting="none"
          value={activeItemLabel || undefined}
        />

        {!disableChildrenSelect && (
          <Select
            isCombobox={false}
            isDisabled={!openItemChilds.length || openItemChilds[0].id === -2}
            items={openItemChilds.map(x => x.label)}
            nilMessage={intl.formatMessage({ id: 'global.filter' })}
            onChange={function (e) { onMenuChildSelectChange(e ?? '') }}
            shouldCheckItemAvailability={false}
            sorting="none"
            value={activeChildId === -2 ? intl.formatMessage({ id: 'global.filter' }) : activeChildLabel}
          />
        )}
      </div>
    </>

  )
}
