import { Text } from '@creditinfo-ui/atoms'

import StepsIndicator from './StepsIndicator'
import CompanySelect from '../CompanySelect'

interface Props {
  tabs: string[]
  codesUploaded: boolean
  codesAccepted: number
  codesNotExist: number
  codesIncompleteData: number
  setCodesUploaded: Function
  setCodesAccepted: Function
  setCodesIncompleteData: Function
  setCodesNotExist: Function
}

export default function StepSelectCompanies ({ tabs, codesUploaded, codesAccepted, codesNotExist, codesIncompleteData, setCodesUploaded, setCodesAccepted, setCodesIncompleteData, setCodesNotExist }: Props): JSX.Element {
  return (
    <div className="container my-14 mx-auto xl:px-64 place-self-start">
      <div className="mb-8 md:mb-14">
        <Text
          message={{ id: 'selectCompanies.upload.title' }}
          variant="viewTitle"
        />
      </div>

      <StepsIndicator
        activeStep={0}
        steps={tabs}
      />

      <CompanySelect
        codesAccepted={codesAccepted}
        codesIncompleteData={codesIncompleteData}
        codesNotExist={codesNotExist}
        codesUploaded={codesUploaded}
        setCodesAccepted={function (count: number) { setCodesAccepted(count) }}
        setCodesIncompleteData={function (count: number) { setCodesIncompleteData(count) }}
        setCodesNotExist={function (count: number) { setCodesNotExist(count) }}
        setCodesUploaded={function (value: boolean) { setCodesUploaded(value) }}
      />
    </div>
  )
}
