import { IRenderer } from 'fela';
import { ReactNode } from 'react';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { BaseNativeTheme, BaseTheme, Direction } from './types';
import { DirectionContext } from './contexts';

export interface StylesProviderProps {
	children: ReactNode;
	direction: Direction;
	renderer: IRenderer;
	theme?: BaseTheme | BaseNativeTheme;
}

const defaultTheme = {};

export const StylesProvider = ({ children, direction, renderer, theme }: StylesProviderProps) => (
	<RendererProvider renderer={renderer}>
		<ThemeProvider theme={theme ?? defaultTheme}>
			<DirectionContext.Provider value={direction}>{children}</DirectionContext.Provider>
		</ThemeProvider>
	</RendererProvider>
);
