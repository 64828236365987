import { ChangeEventHandler, FocusEventHandler, ForwardedRef, ReactNode, forwardRef } from 'react';

import { useGeneratedId } from '@creditinfo-ui/utils';
import { Style, StyleObject, prepareStyle, useStyles } from '@creditinfo-ui/styles';

export const RADIO_SIZE = '2rem';

interface InputStyleProps {
	isDisabled: boolean;
}

const inputStyle = prepareStyle<InputStyleProps>((utils, { isDisabled }) => ({
	height: 'auto',
	opacity: 0,
	position: 'absolute',
	zIndex: -1,
	selectors: {
		':checked + label::after': {
			backgroundColor: isDisabled ? utils.colors.gray400 : utils.colors.gray800,
		},
	},
	extend: {
		condition: !isDisabled,
		style: {
			selectors: {
				':checked + label::before, :focus + label::before, :active + label::before': {
					backgroundColor: utils.colors.white,
					borderColor: utils.colors.gray800,
				},
				':focus + label::before': {
					boxShadow: utils.boxShadows.focusDrop(utils.transparentize(0.7, utils.colors.primary)),
					outline: 0,
				},
			},
		},
	},
}));

interface LabelStyleProps {
	isDisabled: boolean;
}

const labelStyle = prepareStyle<LabelStyleProps>((utils, { isDisabled }) => {
	const circleStyle: StyleObject = {
		borderRadius: utils.borders.radii.round,
		content: '""',
		display: 'block',
		height: RADIO_SIZE,
		insetInlineStart: 0,
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: RADIO_SIZE,
	};

	return {
		color: isDisabled ? utils.colors.gray400 : utils.colors.gray800,
		cursor: isDisabled ? 'not-allowed' : 'pointer',
		display: 'block',
		fontSize: utils.fontSizes.base,
		fontWeight: utils.fontWeights.semiBold,
		margin: 0,
		minHeight: RADIO_SIZE,
		paddingInlineStart: utils.sum([RADIO_SIZE, utils.spacings.sm]),
		position: 'relative',
		selectors: {
			'::before': {
				...circleStyle,
				backgroundColor: isDisabled ? 'transparent' : utils.colors.white,
				borderColor: utils.colors.gray300,
				borderStyle: 'solid',
				borderWidth: utils.borders.widths.md,
				boxShadow: 'none',
				transitionDuration: utils.transitions.speeds.default,
				transitionTimingFunction: utils.transitions.easing,
			},
			'::after': {
				...circleStyle,
				backgroundColor: 'transparent',
				transform: 'translateY(-50%) scale(0.5)',
			},
		},
		extend: {
			condition: !isDisabled,
			style: {
				selectors: {
					':hover::before': {
						borderColor: utils.colors.gray800,
					},
				},
			},
		},
	};
});

export interface RadioProps {
	customStyle?: Style<LabelStyleProps>;
	id?: string;
	isChecked?: boolean;
	isDisabled?: boolean;
	label?: ReactNode;
	name?: string;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	value?: string;
}

export const Radio = forwardRef(
	(
		{
			customStyle,
			id: idProp,
			isChecked,
			isDisabled = false,
			label,
			name,
			onBlur,
			onChange,
			onFocus,
			value,
		}: RadioProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const { applyStyle } = useStyles();
		const generatedId = useGeneratedId();
		const inputId = idProp ?? `Radio_input_${generatedId}`;

		return (
			<>
				<input
					checked={isChecked}
					className={applyStyle(inputStyle, { isDisabled })}
					disabled={isDisabled}
					id={inputId}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					onFocus={onFocus}
					ref={ref}
					type="radio"
					value={value}
				/>
				<label className={applyStyle([labelStyle, customStyle], { isDisabled })} htmlFor={inputId}>
					{label ?? null}
				</label>
			</>
		);
	}
);
