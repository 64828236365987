import { ReactNode, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from '@creditinfo-ui/toasts'

import { removeError } from '../store/errors'
import { useGlobalStyles } from '../hooks/useGlobalStyles'
import Navbar from './Navbar'

interface Props {
  children?: ReactNode
  footer?: ReactNode
}

export default function Layout ({ children, footer }: Props): JSX.Element {
  const intl = useIntl()

  const [menuOpen, setMenuOpen] = useState(false)
  const errors = useSelector((state: any) => state.errors)
  const dispatch = useDispatch()

  useGlobalStyles()

  function closeError (err: string): void {
    dispatch(removeError(err))
  }

  return (
    <div className="flex flex-col h-screen">
      {errors.length > 0
        ? (
          <div className="absolute top-16 right-4 z-50">
            { errors.map((e: string, idx: number) => (
              <Toast
                content={e}
                id={`toast-${idx}`}
                key={e}
                onClose={function () { closeError(e) }}
                onExpand={function () { }}
                shouldAutomaticallyClose
                type="warning"
              />
            ))}
          </div>
          )
        : null}

      <Navbar
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      <main className={`${menuOpen ? 'hidden md:block' : ''} flex-1 overflow-y-auto`}>
        <div
          className="min-h-full px-4 pb-40 md:px-6 md_pb-0 flex items-center justify-center"
          id="main-wrapper"
        >
          { children }
        </div>
      </main>

      <div className={`${menuOpen ? 'hidden md:block' : ''}`}>
        {footer}
      </div>

      <div className="bg-white text-gray-other border-t border-t-grey-light">
        <div className="container mx-auto py-5 px-4 lg:flex lg:flex-row items-center justify-center text-sm">
          <span className="pr-4 border-r border-grey-light">
            <FormattedMessage id="app.footer.company" />
          </span>

          <span className="px-4 border-r border-grey-light">
            <FormattedMessage id="app.footer.address" />
          </span>

          <span className="px-4 border-r border-grey-light">
            <a
              className="hover:underline"
              href={`tel:${intl.formatMessage({ id: 'app.footer.phone' })}`}
            >
              <FormattedMessage id="app.footer.phone" />
            </a>
          </span>

          <span className="px-4 border-r border-grey-light">
            <a
              className="hover:underline"
              href={`mailto:${intl.formatMessage({ id: 'app.footer.email' })}`}
            >
              <FormattedMessage id="app.footer.email" />
            </a>
          </span>

          <span className="px-4 border-r border-grey-light">
            <a
              className="hover:underline"
              href={intl.formatMessage({ id: 'app.footer.cookiesUrl' })}
              rel="noreferrer"
              target='_blank'
            >
              <FormattedMessage id="app.footer.cookies" />
            </a>
          </span>

          <span className="pl-4 text-gray-other">
            <a
              className="hover:underline"
              href={intl.formatMessage({ id: 'app.footer.dataUrl' })}
              rel="noreferrer"
              target='_blank'
            >
              <FormattedMessage id="app.footer.data" />
            </a>
          </span>
        </div>
      </div>

    </div>
  )
}
