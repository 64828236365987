import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { TextInput } from '@creditinfo-ui/atoms'

import Checkbox from './Checkbox'
import CheckboxInterval from './CheckboxInterval'
import DateInterval from './DateInterval'
import Interval from './Interval'
import Radio from './Radio'

interface Props {
  name: string
  value: string[]
  onChange: Function
  type: string
  options: FilterOption[]
  min: number
  max: number
  diagram: FilterDiagram[]
  filterText?: string
  filterName?: string
}

export default function Filter ({ name, value, onChange, type, options, min, max, diagram, filterText, filterName }: Props): JSX.Element {
  const intl = useIntl()

  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch('')
  }, [type, name])

  return (
    <div className="object-contain">
      {{
        binary: (
          <Radio
            key={name}
            onChange={onChange}
            options={options}
            value={value}
          />
        ),
        checkbox: (
          <>
            <div className="mb-10 w-2/6">
              <TextInput
                onChange={function (e) { setSearch(e.target.value) }}
                placeholder={intl.formatMessage({ id: 'selectFilters.filter.search' })}
                value={search}
              />
            </div>

            <Checkbox
              key={name}
              onChange={onChange}
              options={options}
              search={search}
              value={value}
            />
          </>
        ),
        hierarchical_checkbox: (
          <>
            <div className="mb-10 w-full md:w-2/6">
              <TextInput
                onChange={function (e) { setSearch(e.target.value) }}
                placeholder={intl.formatMessage({ id: 'selectFilters.filter.search' })}
                value={search}
              />
            </div>

            <Checkbox
              key={name}
              onChange={onChange}
              options={options}
              search={search}
              value={value}
            />
          </>
        ),
        hierarchical_linked_checkbox: (
          <>
            <div className="mb-10 w-full md:w-2/6">
              <TextInput
                onChange={function (e) { setSearch(e.target.value) }}
                placeholder={intl.formatMessage({ id: 'selectFilters.filter.search' })}
                value={search}
              />
            </div>

            <Checkbox
              key={name}
              linked
              onChange={onChange}
              options={options}
              search={search}
              value={value}
            />
          </>
        ),
        hierarchical_linked_checkbox_interval: (
          <CheckboxInterval
            diagram={diagram}
            filterName={filterName}
            filterText={filterText}
            key={name}
            max={max}
            min={min}
            onChange={onChange}
            options={options}
            value={value}
          />
        ),
        date_interval: (
          <DateInterval
            key={name}
            onChange={onChange}
            value={value}
          />
        ),
        interval: (
          <Interval
            diagram={diagram}
            filterName={filterName}
            filterText={filterText}
            key={name}
            max={max}
            min={min}
            onChange={onChange}
            value={value}
          />
        ),
        unary: (
          <Checkbox
            key={name}
            onChange={onChange}
            options={options}
            value={value}
          />
        )
      }[type]}
    </div>
  )
}
