import { assignStyle } from 'css-in-js-utils';
import { getIsPlainObject } from '@creditinfo-ui/utils';
import { keys } from 'ramda';
import { StyleObject } from '../types';
import { breakpointMediaQueries, breakpoints } from '../breakpoints';

// NOTE: Mutations for maximum performance. Yuck.
const resolveResponsiveValues = (styleObject: StyleObject) => {
	Object.entries(styleObject).forEach(([propertyName, propertyValue]) => {
		if (getIsPlainObject(propertyValue)) {
			const isResponsiveValue = keys(propertyValue).some(key => breakpoints.includes(key));

			if (isResponsiveValue) {
				Object.entries(propertyValue).forEach(([breakpointName, breakpointPropertyValue]) => {
					if (breakpointName === 'xs') {
						assignStyle(styleObject as any, { [propertyName]: breakpointPropertyValue });

						return;
					}

					const mediaQuery = breakpointMediaQueries[breakpointName];

					if (!mediaQuery) {
						return;
					}

					assignStyle(styleObject as any, {
						[mediaQuery]: {
							[propertyName]: breakpointPropertyValue,
						},
					});
				});

				// NOTE: If `propertyValue` contains an `xs` property, it will get overwritten by the value.
				// If it doesn't contain this property, the `{ sm, md, lg, xl }` object will stay, so we need
				// to remove it. See the tests to understand the desired behaviour.
				if (getIsPlainObject(styleObject[propertyName])) {
					delete styleObject[propertyName];
				}
			} else {
				resolveResponsiveValues(propertyValue as StyleObject);
			}
		}
	});

	return styleObject;
};

export const makeResponsiveValuePlugin = () => resolveResponsiveValues;
