import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Menu, MenuItem, Popover } from '@creditinfo-ui/atoms'

import { setUser } from '../store/user'
import { apiGet, apiPost } from '../hooks/useApi'
import { useSessionStorage } from '../hooks/useStorage'
import { Context } from './Wrapper'
import Icon from './Icon'
import logo from '../assets/logo.svg'

interface Props {
  menuOpen: boolean
  setMenuOpen: Function
}

declare global {
  interface Window { CookieInformation: any }
}

export default function Navbar ({ menuOpen, setMenuOpen }: Props): JSX.Element {
  const navigate = useNavigate()
  const context = useContext(Context)
  const dispatch = useDispatch()
  // const [searchParams, setSearchParams] = useSearchParams()

  const user = useSelector((state: any) => state.user)
  const [users, setUsers] = useSessionStorage<User[]>('users', [])

  function signIn (): void {
    navigate(`/sign-in?next=${location.pathname}`)
  }

  async function signOut (): Promise<void> {
    const { status, error } = await apiGet('authLogout')

    if (error !== undefined) return
    if (status !== 'success') return

    dispatch(setUser(null))
    setUsers([])

    location.reload()
  }

  async function selectUser (userId: number): Promise<void> {
    const { id, firstName, lastName, companyName, error } = await apiPost('authChooseUser', { body: { id: userId } })

    if (error !== undefined) return

    dispatch(setUser({ id, firstName, lastName, companyName }))
  }

  function selectLanguage (language: string): any {
    context.selectLanguage?.(language)

    document.getElementById('CookieConsent')?.setAttribute('data-culture', language)
    window.CookieInformation.loadConsent()

    localStorage.setItem('language', language)
    // searchParams.set('lang', language)
    // setSearchParams(searchParams)
  }

  return (
    <div className="sticky top-0 h-16 z-50 font-bold text-silver bg-black flex items-center">
      <div className="w-full xl:container ml-4 md:mr-4 xl:mx-auto xl:px-4 flex items-center justify-between gap-4 xl:gap-14">
        <Link to="/">
          <img
            alt="logo"
            className="h-16"
            src={logo}
          />
        </Link>

        <div
          className="md:hidden h-16 w-16 flex items-center justify-center bg-red-brand cursor-pointer"
          onClick={function () { setMenuOpen(!menuOpen) }}
        >
          <Icon type={menuOpen ? 'close' : 'burger'} />
        </div>

        <div className={`${menuOpen ? 'flex' : 'hidden md:flex'} fixed md:static top-16 right-0 bottom-0 left-0 z-30 pt-8 md:pt-0 flex-col md:flex-row items-center gap-4 bg-black`}>
          {user !== null
            ? (
              <Popover
                popover={(
                  <Menu className="font-normal">
                    <MenuItem>
                      <Link to="/archive">
                        <FormattedMessage id="navbar.archive" />
                      </Link>
                    </MenuItem>

                    <hr className="border-gray-light" />

                    {users.map(x => (
                      <MenuItem
                        className={x.id === user.id ? 'font-bold' : ''}
                        key={x.id}
                        onClick={async function () { await selectUser(x.id) }}
                      >
                        {x.companyName}
                      </MenuItem>
                    ))}

                    <hr className="border-gray-light" />

                    <MenuItem
                      className="text-red-brand"
                      onClick={async function () { await signOut() }}
                    >
                      <FormattedMessage id="navbar.signout" />
                    </MenuItem>
                  </Menu>
                  )}
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  {user.companyName}

                  <Icon type="chevron_up" />
                </div>
              </Popover>
              )
            : null}

          {user === null && location.pathname !== '/sign-in'
            ? (
              <Button
                onClick={function () { signIn() }}
                size="small"
              >
                <FormattedMessage id="navbar.signin" />
              </Button>
              )
            : null}

          <div className="md:pl-4 flex items-center gap-7 uppercase md:border-l border-grey">
            <a
              className={`${context.locale === 'et' ? 'border-red-brand' : 'border-transparent'} h-16 flex items-center border-y-4 border-t-transparent cursor-pointer`}
              onClick={function () { selectLanguage('et') }}
            >
              <FormattedMessage id="navbar.language.est" />
            </a>

            <a
              className={`${context.locale === 'en' ? 'border-red-brand' : 'border-transparent'} h-16 flex items-center border-y-4 border-t-transparent cursor-pointer`}
              onClick={function () { selectLanguage('en') }}
            >
              <FormattedMessage id="navbar.language.eng" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
