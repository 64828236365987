import Dexie, { Table } from 'dexie'

class MyDexie extends Dexie {
  codesAccepted!: Table<Company>
  codesNotExist!: Table<Company>
  codesIncompleteData!: Table<Company>

  constructor () {
    super('codesDatabase')
    this.version(1).stores({
      codesAccepted: '&regCode, companyName',
      codesNotExist: '&regCode',
      codesIncompleteData: '&regCode, companyName'
    })
  }
}

export const db = new MyDexie()

export async function resetDb (): Promise<void> {
  await db.codesAccepted.clear()
  await db.codesNotExist.clear()
  await db.codesIncompleteData.clear()
}
