export const defaultLineHeights = {
	badge: 1.25,
	base: 1.5,
	title: { xs: 1.2, md: 1.4 },
	navigation: 4 / 3,
} as const;

export type LineHeight = keyof typeof defaultLineHeights;

export type LineHeights = typeof defaultLineHeights;
