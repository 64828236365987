import {
	StylesProvider as FelaStylesProvider,
	StylesProviderProps as FelaStylesProviderProps,
} from '@creditinfo-ui/fela';
import { mergeDeepRight } from 'ramda';
import { useMemo } from 'react';

import {
	NativeTheme,
	PartialNativeTheme,
	PartialTheme,
	Theme,
	defaultNativeTheme,
	defaultTheme,
} from './theme';

export interface StylesProviderProps
	extends Omit<FelaStylesProviderProps, 'nativeTheme' | 'theme'> {
	nativeTheme?: PartialNativeTheme;
	theme?: PartialTheme;
}

export const StylesProvider = ({ nativeTheme, theme, ...otherProps }: StylesProviderProps) => {
	const nextTheme: Theme | NativeTheme = useMemo(
		() =>
			nativeTheme
				? mergeDeepRight(defaultNativeTheme, nativeTheme)
				: mergeDeepRight<any, any>(defaultTheme, theme ?? {}),
		[nativeTheme, theme]
	);

	return <FelaStylesProvider {...otherProps} theme={nextTheme} />;
};
