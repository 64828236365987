import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Radio, Text } from '@creditinfo-ui/atoms'

import { apiGet, apiPost } from '../../hooks/useApi'
import { useSessionStorage } from '../../hooks/useStorage'
import StepsIndicator from './StepsIndicator'
import { format } from 'date-fns'
import { formatDate } from '../../helpers'

interface Props {
  tabs: string[]
  stats?: Stats
  selectedDataFields: DataField[]
  fileFormat: string
  setFileFormat: Function
  filterValues?: FilterValue[]
}

interface DataFieldWithCategory {
  dataField: DataField[]
  category: DataCategory
}

export default function StepOrder ({ tabs, stats, selectedDataFields, filterValues, fileFormat, setFileFormat }: Props): JSX.Element {
  const intl = useIntl()

  const [activePage] = useSessionStorage('activePage', '/')
  const [dataFields, setDataFields] = useState<DataFieldWithCategory[]>([])
  const [sampleFile, setSampleFile] = useState({
    fileLinkXLSX: '',
    fileLinkCSV: ''
  })

  const serviceTypeName = useMemo(() => {
    switch (activePage) {
      case '/own-sample':
        return intl.formatMessage({ id: 'ownSample.title' })
      case '/select-sample':
        return intl.formatMessage({ id: 'selectSample.title' })
      case '/global-sample':
        return intl.formatMessage({ id: 'globalSample.title' })
      default:
        return ''
    }
  }, [intl.locale, activePage])

  const disclaimer = useMemo(() => {
    switch (activePage) {
      case '/own-sample':
        return intl.formatMessage({ id: 'order.summary.disclaimer' })
      case '/select-sample':
        return intl.formatMessage({ id: 'order.summary.disclaimer' })
      case '/global-sample':
        return intl.formatMessage({ id: 'order.summary.disclaimerGlobal' })
      default:
        return ''
    }
  }, [intl.locale, activePage])

  useEffect(() => {
    async function getFields (): Promise<void> {
      const { allDataFields, error } = await apiGet('getDataFields', {
        params: {
          forInternational: activePage === '/global-sample' ? 1 : 0
        }
      })

      if (error !== undefined) return

      const filteredCategories: DataCategory[] = allDataFields.filter((category: DataCategory) =>
        (activePage === '/global-sample' || category.iconName !== 'popular') && category.dataFields.some((dataField: DataField) =>
          selectedDataFields.some((selectedField: DataField) => selectedField.dataFieldName === dataField.dataFieldName)))

      const groupedDataFields: DataFieldWithCategory[] = filteredCategories.reduce((acc: DataFieldWithCategory[], category: DataCategory) => {
        const categoryDataFields: DataField[] = category.dataFields.filter((dataField: DataField) =>
          selectedDataFields.some((selectedField: DataField) => selectedField.dataFieldName === dataField.dataFieldName))
        acc.push({ category, dataField: categoryDataFields })

        return acc
      }, [])

      const sortedDataFields: DataFieldWithCategory[] = groupedDataFields.sort((a: DataFieldWithCategory, b: DataFieldWithCategory) =>
        a.category.categoryPosition - b.category.categoryPosition)

      setDataFields(sortedDataFields)
    }

    async function getSample (): Promise<void> {
      const { fileLinkXLSX, fileLinkCSV, error } = await apiPost('postSampleMAQuery', {
        body: {
          selectedDataFields: selectedDataFields.map(x => ({
            dataFieldName: x.dataFieldName,
            quartersIncluded: x.quartersIncluded
          }))
        }
      })

      if (error !== undefined) return

      getFields().catch(console.error)
      setSampleFile({ fileLinkXLSX, fileLinkCSV })
    }

    getSample().catch(console.error)
  }, [intl.locale, selectedDataFields])

  return (
    <div className="container my-14 mx-auto xl:px-64 place-self-start">
      <div className="mb-8 md:mb-14">
        <Text
          message={{ id: 'order.title' }}
          variant="viewTitle"
        />
      </div>

      <StepsIndicator
        activeStep={2}
        steps={tabs}
      />

      <div className="pt-6 grid lg:grid-cols-3 gap-6 border-t-[3px] border-red-brand">
        <div className="lg:col-span-2">
          <Text
            message={{ id: 'order.subTitle' }}
            variant="extraTitle"
          />

          <div className="mt-5 pb-2 border-b border-gray-light">
            <div>
              <span className="mr-1 font-bold">
                <FormattedMessage id="order.serviceType" />
              </span>

              {serviceTypeName}
            </div>

            <div>
              <span className="mr-1 font-bold">
                <FormattedMessage id="order.dateName" />
              </span>
              <span>
                {formatDate(new Date())}
              </span>
            </div>
          </div>

          {activePage !== '/global-sample'
            ? (
              <div className="py-2 border-b border-gray-light">
                <div className="font-bold">
                  <FormattedMessage id="order.selectFormat" />
                </div>

                <div className="my-2 flex flex-col lg:flex-row gap-6">
                  <div className="flex gap-2">
                    <Radio
                      isChecked={fileFormat === 'xlsx'}
                      label={intl.formatMessage({ id: 'order.formatXLSX' })}
                      onChange={function (e) { if (e.target.checked) setFileFormat('xlsx') }}
                    />

                    <a
                      className="text-teal-brand font-bold"
                      href={sampleFile.fileLinkXLSX}
                    >
                      <FormattedMessage id="order.downloadSample" />
                    </a>
                  </div>

                  <div className="flex gap-2">
                    <Radio
                      isChecked={fileFormat === 'csv'}
                      label={intl.formatMessage({ id: 'order.formatCSV' })}
                      onChange={function (e) { if (e.target.checked) setFileFormat('csv') }}
                    />

                    <a
                      className="text-teal-brand font-bold"
                      href={sampleFile.fileLinkCSV}
                    >
                      <FormattedMessage id="order.downloadSample" />
                    </a>
                  </div>
                </div>
              </div>
              )
            : null}

          <div className={`grid grid-cols-1 ${activePage !== '/own-sample' ? 'md:grid-cols-2' : ''}`}>
            {activePage !== '/own-sample' && (
              <div className="py-2">
                <div className="font-bold">
                  <FormattedMessage id="archive.appliedFilters" />
                </div>

                <ul className="list-disc ml-6">
                  {filterValues
                    ? filterValues.map(x => {
                      let appliedFiltersArr: string[]

                      // Format date filters
                      if (x.filterType === 'date_interval') {
                        appliedFiltersArr = x.filterValues.filter((item) => item !== null).map((item) => item && format(new Date(item), 'dd.MM.yyyy'))
                          .map((item) => item === '' ? '...' : item)
                      } else {
                        // FilterValues are displayed for intervals
                        appliedFiltersArr = (!x.filterValuesText.length ? x.filterValues : x.filterValuesText).filter((item) => item !== null)
                          .map((item) => item === '' ? '...' : item)
                      }

                      const appliedFilters = appliedFiltersArr.join(x.filterType === 'date_interval' || x.filterType === 'interval' ? ' - ' : ', ')

                      return (
                        <li key={x.filterID}>
                          {`${x.filterText}: ${appliedFilters}`}
                        </li>
                      )
                    })
                    : null}
                </ul>
              </div>
            )}

            <div className="py-2">
              <div className="font-bold">
                <FormattedMessage id="order.selectedFields" />
              </div>

              <ul className="list-disc ml-6">
                {dataFields.map(x => (
                  <li key={x.category.categoryID}>
                    {`${x.category.category}: ${x.dataField.map((item: DataField) => item.dataFieldText).join(', ')}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div className="py-6 px-5 bg-silver font-bold">
            {stats?.filtersCount !== undefined
              ? (
                <div className="pb-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.filtersName" />
                  </div>

                  {stats?.filtersCount !== null ? intl.formatNumber(stats?.filtersCount) : '-'}
                </div>
                )
              : null}

            {stats?.dataFieldsCount !== undefined
              ? (
                <div className="pb-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.fieldsName" />
                  </div>

                  {stats?.dataFieldsCount !== null ? intl.formatNumber(stats?.dataFieldsCount) : '-'}
                </div>
                )
              : null}

            {stats?.companiesCount !== undefined
              ? (
                <div className="pb-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.countName" />
                  </div>

                  {stats?.companiesCount !== null ? intl.formatNumber(stats?.companiesCount) : '-'}
                </div>
                )
              : null}

            {stats?.sum !== undefined
              ? (
                <div className="pt-3 flex justify-between border-t border-gray-other">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.priceName" />
                  </div>

                  {stats?.sum !== null ? intl.formatNumber(stats?.sum, { style: 'currency', currency: 'EUR' }) : '-'}
                </div>
                )
              : null}

            {stats?.sumWithVat !== undefined
              ? (
                <div className="pt-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.priceWithVatName" />
                  </div>

                  {stats?.sumWithVat !== null ? intl.formatNumber(stats?.sumWithVat, { style: 'currency', currency: 'EUR' }) : '-'}
                </div>
                )
              : null}

            <div className="pt-3 font-normal text-sm">
              {disclaimer}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
