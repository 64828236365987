// https://github.com/robinweser/fela/blob/master/packages/fela-plugin-typescript/src/index.js
import { getIsPlainObject } from '@creditinfo-ui/utils';
import { StyleObject } from '../types';

const applyNestedSelectors = (styleObject: StyleObject): StyleObject => {
	for (const key in styleObject) {
		const value = styleObject[key];

		if (key === 'selectors' && getIsPlainObject(value)) {
			Object.assign(styleObject, applyNestedSelectors(value));

			delete styleObject.selectors;
		} else if (getIsPlainObject(value)) {
			applyNestedSelectors(value);
		}
	}

	return styleObject;
};

export const makeTypedSelectorsPlugin = () => applyNestedSelectors;
