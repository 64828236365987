import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Text } from '@creditinfo-ui/atoms'

import Layout from '../components/Layout'

export default function OrderSuccess (): JSX.Element {
  return (
    <Layout>
      <div className="h-full flex flex-col justify-center items-center gap-2">
        <div className="mb-14 text-green-brand">
          <svg
            fill="none"
            height="185"
            viewBox="0 0 185 185"
            width="185"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M92.5 185C41.4323 185 0 143.568 0 92.5C0 41.4323 41.4323 0 92.5 0C143.568 0 185 41.4323 185 92.5C185 143.568 143.568 185 92.5 185ZM92.5 10.2135C47.2135 10.2135 10.2135 47.2135 10.2135 92.5C10.2135 137.786 47.2135 174.786 92.5 174.786C137.786 174.786 174.786 137.786 174.786 92.5C174.786 47.2135 137.786 10.2135 92.5 10.2135Z"
              fill="currentColor"
            />

            <path
              d="M82.479 117.552C81.1294 117.552 79.7812 116.974 78.8172 116.011L59.162 96.354C57.2344 94.4264 56.8499 91.1506 58.584 89.2242C60.5116 86.9121 63.9798 86.7185 66.0996 88.8384L82.4805 105.219H82.6728L119.095 68.7974C121.022 66.8698 124.105 66.4853 126.224 68.2194C128.537 70.147 128.73 73.6152 126.61 75.735L86.1415 116.204C85.1777 117.166 83.8294 117.552 82.4797 117.552L82.479 117.552Z"
              fill="currentColor"
            />
          </svg>
        </div>

        <Text
          message={{ id: 'orderSuccess.title' }}
          variant="extraTitle"
        />

        <FormattedMessage
          id="orderSuccess.text"
        />

        <Link
          className="mt-2 text-teal-brand font-bold"
          to="/"
        >
          <FormattedMessage id="orderSuccess.backLink" />
        </Link>

      </div>
    </Layout>
  )
}
