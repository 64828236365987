import { AnyAction } from 'redux'

export const errorsReducer = (state: string[] = [], action: AnyAction): string[] => {
  switch (action.type) {
    case 'SET_ERRORS':
      state = [...state, ...action.payload]

      return state
    case 'REMOVE_ERROR':
      state = state.filter((x: string) => x !== action.payload)

      return state
    default:
      return state
  }
}

export const setErrors = (errors: string[] | null): AnyAction => {
  return {
    type: 'SET_ERRORS',
    payload: errors
  }
}

export const removeError = (error: string): AnyAction => {
  return {
    type: 'REMOVE_ERROR',
    payload: error
  }
}
