import type * as CSS from 'csstype';

export type TransitionSpeed = 'slower' | 'default' | 'faster';

export const defaultTransitions = {
	easing: 'ease-in-out',
	speeds: {
		slower: '360ms',
		default: '180ms',
		faster: '90ms',
	},
};

export interface Transitions {
	easing: CSS.Property.TransitionTimingFunction;
	speeds: Record<TransitionSpeed, CSS.Property.TransitionDuration>;
}
