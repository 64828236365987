import { Style, StyleObject, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';
import { ComponentType, InputHTMLAttributes, createContext, forwardRef, useContext } from 'react';
import type * as CSS from 'csstype';
import { InputWrapperContext } from './InputWrapper';
import { AddonWidths } from '../types';

// TODO: Use an existing `utils.colors.gray###` color.
export const TEXT_INPUT_COLOR = '#495057';

export type TextInputType =
	| 'button'
	| 'checkbox'
	| 'color'
	| 'date'
	| 'datetime-local'
	| 'email'
	| 'file'
	| 'hidden'
	| 'image'
	| 'month'
	| 'number'
	| 'password'
	| 'radio'
	| 'range'
	| 'reset'
	| 'search'
	| 'submit'
	| 'tel'
	| 'text'
	| 'time'
	| 'url'
	| 'week';

export type TextInputVariant = 'form' | 'subtle';

// NOTE: The text input must be usable in a lot of contexts, including integration with other
// libraries which might depend on passing `disabled` and `readOnly` down.
interface CompatibilityTextInputProps {
	/** @deprecated Use `isDisabled` instead. */
	disabled?: boolean;
	/** @deprecated Use `isReadOnly` instead. */
	readOnly?: boolean;
}

export interface TextInputProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled' | 'readOnly'>,
		CompatibilityTextInputProps {
	customStyle?: Style<TextInputStyleProps>;
	/**
	 * When `false`, disables all `:focus` styles. Useful when embedding the input in other elements.
	 */
	hasFocusedStyle?: boolean;
	isDisabled?: boolean;
	isFocused?: boolean;
	isInvalid?: boolean;
	isReadOnly?: boolean;
	// NOTE: `ElementType<InputHTMLAttributes<HTMLInputElement>>` produces a union type which is too
	// complex for TypeScript to handle.
	renderInput?: ComponentType<InputHTMLAttributes<HTMLInputElement>> | 'input';
	testName?: string;
	type?: TextInputType;
	variant?: TextInputVariant;
}

export interface TextInputStyleProps {
	addonWidths?: AddonWidths;
	hasFocusedStyle: boolean;
	isDisabled: boolean;
	isFocused: boolean;
	isInvalid: boolean;
	isReadOnly: boolean;
	variant: TextInputVariant;
}

// NOTE: This context allows the text input to be used in the old CBSUI form architecture.
// It passes `hasError` information down through classes and form-specific context, which
// we cannot import here due to cyclic dependencies.
export const LegacyTextInputContext = createContext({ hasError: false });

export const textInputStyle = prepareStyle<TextInputStyleProps>(
	(
		utils,
		{ addonWidths, hasFocusedStyle, isReadOnly, isDisabled, isInvalid, isFocused, variant }
	) => {
		const focusedStyleObject: StyleObject = {
			outline: 0,

			extend: [
				{
					condition: hasFocusedStyle,
					style: {
						borderColor: utils.colors.gray800,
						boxShadow: utils.boxShadows.focusDrop(utils.transparentize(0.7, utils.colors.primary)),

						extend: {
							condition: isInvalid,
							style: {
								borderColor: utils.colors.danger,
								boxShadow: utils.boxShadows.invalid,
							},
						},
					},
				},
				{
					condition: isInvalid,
					style: {
						color: TEXT_INPUT_COLOR,
					},
				},
			],
		};

		const inputHeightsByVariant: Record<TextInputVariant, CSS.Property.Height> = {
			form: utils.sizes.control.medium,
			subtle: utils.sizes.control.small,
		};

		return {
			backgroundClip: 'padding-box',
			backgroundColor: '#fff',
			borderRadius: utils.borders.radii.basic,
			borderStyle: 'solid',
			borderWidth: utils.borders.widths.md,
			color: TEXT_INPUT_COLOR,
			display: 'block',
			fontSize: utils.fontSizes.base,
			fontWeight: utils.fontWeights.normal,
			height: inputHeightsByVariant[variant],
			lineHeight: utils.lineHeights.base,
			// NOTE: Although `textAlign: 'start'` is already the default style, we want to resolve
			// the alignment using `fela-plugin-bidi` (based on `DirectionContext`) instead of using
			// the direction of the `input` element. This allows us to pass `dir="ltr"` to phone inputs,
			// ensuring that in RTL they're still aligned to the right, but written left-to-right.
			textAlign: 'start',
			transitionDuration: utils.transitions.speeds.default,
			transitionProperty: 'color, box-shadow, border-color',
			transitionTimingFunction: utils.transitions.easing,

			selectors: {
				'::placeholder': {
					color: utils.colors.gray400,
				},
			},

			extend: [
				{
					condition: variant === 'form',
					style: {
						borderColor: utils.colors.gray300,
						paddingBottom: utils.spacings.sm,
						paddingInlineEnd: addonWidths?.end
							? utils.sum([utils.spacings.sm, addonWidths.end])
							: utils.spacings.md,
						paddingInlineStart: addonWidths?.start
							? utils.sum([
									utils.spacings.md,
									// NOTE: `utils.spacings.xxs` exactly matches the text input border width.
									// Necessary for proper alignment of `addon` with `Select` menu items.
									utils.multiply(-1, utils.spacings.xxs),
									addonWidths.start,
							  ])
							: utils.spacings.md,
						paddingTop: utils.spacings.sm,
						width: '100%',
					},
				},
				{
					condition: variant === 'subtle',
					style: {
						borderColor: 'transparent',
						paddingBottom: utils.spacings.xxs,
						paddingInlineEnd: addonWidths?.end ?? utils.spacings.xs,
						paddingInlineStart: addonWidths?.start ?? utils.spacings.xs,
						paddingTop: utils.spacings.xxs,
					},
				},
				{
					condition: isInvalid,
					style: {
						borderColor: utils.colors.danger,
						color: utils.colors.danger,
					},
				},
				{
					condition: isDisabled,
					style: {
						backgroundColor: 'transparent',
						color: utils.colors.gray400,
					},
				},
				{
					condition: !isDisabled,
					style: {
						selectors: {
							':focus, :focus-visible': focusedStyleObject,
						},
					},
				},
				{
					condition: isFocused,
					style: focusedStyleObject,
				},
				{
					condition: !isReadOnly && !isDisabled && !isInvalid,
					style: {
						selectors: {
							':hover': {
								borderColor: utils.colors.gray800,
							},
						},
					},
				},
			],
		};
	}
);

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	(props: TextInputProps, ref) => {
		const {
			className,
			customStyle,
			disabled: disabledProp,
			hasFocusedStyle = true,
			isDisabled: isDisabledProp,
			isFocused = false,
			isInvalid: isInvalidProp,
			isReadOnly: isReadOnlyProp,
			name,
			readOnly: readOnlyProp,
			renderInput: Input = 'input',
			testName,
			type = 'text',
			variant = 'form',
			...otherProps
		} = props;

		// NOTE: We must manually destructure all props so they are not passed through to the input.
		const isReadOnly = isReadOnlyProp ?? readOnlyProp ?? false;
		const isDisabled = isDisabledProp ?? disabledProp ?? false;

		const { applyStyle } = useStyles();
		const { addonWidths } = useContext(InputWrapperContext);
		const { hasError } = useContext(LegacyTextInputContext);
		const isInvalid = isInvalidProp ?? hasError;

		return (
			<Input
				className={cx(
					applyStyle([textInputStyle, customStyle], {
						addonWidths,
						hasFocusedStyle,
						isDisabled,
						isFocused,
						isInvalid,
						isReadOnly,
						variant,
					}),
					className
				)}
				data-test-name={testName}
				disabled={isDisabled}
				name={name}
				readOnly={isReadOnly}
				ref={ref}
				type={type}
				{...otherProps}
			/>
		);
	}
);

TextInput.displayName = 'TextInput';
