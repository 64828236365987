import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button } from '@creditinfo-ui/atoms'

import { setUser } from '../store/user'
import { apiGet, apiPost } from '../hooks/useApi'
import { useSessionStorage } from '../hooks/useStorage'
import Layout from '../components/Layout'
import Icon from '../components/Icon'

declare const DokobitIdentity: any

export default function SignIn (): JSX.Element {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const [users, setUsers] = useSessionStorage<User[]>('users', [])
  const [activePage] = useSessionStorage('activePage', '/')
  const [activeStep, setActiveStep] = useSessionStorage('activeStep', '')
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    async function getToken (): Promise<void> {
      const { sessionToken, error } = await apiGet('authCreate')

      if (error !== undefined) return

      setIsLoaded(true)
      new DokobitIdentity({
        container: '#dokobit-identity-container',
        locale: intl.locale,
        sessionToken,
        callback: async (returnToken: string = '') => { if (returnToken !== '') await authUser(returnToken) }
      }).init()
    }

    async function authUser (returnToken: string): Promise<void> {
      try {
        const usersResponse = await apiPost('authLogin', { body: { returnToken } })
        const usersArray: User[] = usersResponse.map((x: User) => x)

        setUsers(usersArray)

        if (usersArray.length === 1) await selectUser(usersArray[0])
      } catch (error) {
        setIsError(true)
        console.error('XXX')
      }
    }

    const script = document.createElement('script')

    script.src = process.env.REACT_APP_DOKOBIT_URL ?? ''
    script.async = true
    script.onload = async () => await getToken().catch(console.error)

    document.body.appendChild(script)

    return function () {
      document.body.removeChild(script)
    }
  }, [intl.locale])

  async function selectUser (user: User): Promise<void> {
    const { id, firstName, lastName, companyName, error } = await apiPost('authChooseUser', { body: { id: user.id } })
    const basename = process.env.PUBLIC_URL ?? ''

    if (error !== undefined) return

    dispatch(setUser({ id, firstName, lastName, companyName }))

    if (activePage === '/own-sample' && activeStep === 'orderForm' && user !== null) setActiveStep('order')
    if (activePage === '/select-sample' && activeStep === 'orderForm' && user !== null) setActiveStep('order')

    navigate(searchParams.get('next')?.replace(basename, '') ?? '/')
  }

  return (
    <Layout>
      {isError
        ? (
          <div className="flex flex-col gap-6 items-center justify-center">
            <div className="pb-8 text-2xl text-center text-red-alert font-semibold">
              <FormattedMessage id="signIn.error" />
            </div>

            <Button
              onClick={function () { location.reload() }}
              size="small"
              type="submit"
              variant={['primary', { isOutlined: true }]}
            >
              <FormattedMessage id="signIn.errorRetry" />
            </Button>
          </div>
          )
        : null}

      {!isError && users.length === 0 && isLoaded
        ? (
          <div
            className="md:py-14 md:pt-4 md:px-28 w-full md:w-2/3 xl:w-1/3 md:max-w-7xl gap-16 md:bg-silver"
            id="dokobit-identity-container"
          />
          )
        : null}

      {!isError && users.length > 0
        ? (
          <div
            className="md:py-16 md:px-28 w-full md:w-2/3 xl:w-1/3 md:max-w-7xl gap-16 md:bg-silver"
          >
            <div className="pb-8 text-2xl text-center font-semibold">
              <FormattedMessage id="signIn.selectCompany" />
            </div>

            <div className="pl-8 bg-white border border-gray-light rounded-xl">
              {users.map((user: User) => (
                <div
                  className="py-6 pr-6 flex items-center gap-6 text-base font-semibold border-b last-of-type:border-b-0 border-gray-light cursor-pointer"
                  key={user.id}
                  onClick={async function () { await selectUser(user) }}
                >
                  <div className="grow">
                    {user.companyName}
                  </div>

                  <div className="h-6 w-6 text-gray-other">
                    <Icon type="chevron_right" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          )
        : null}
    </Layout>
  )
}
