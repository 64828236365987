import { combineReducers, createStore } from 'redux'
import { errorsReducer } from './errors'
import { userReducer } from './user'

const allReducers = combineReducers({
  errors: errorsReducer,
  user: userReducer
})

const store = createStore(allReducers)

export default store
