import type * as CSS from 'csstype';

export const defaultColors = {
	primary: '#c40f11',
	secondary: '#2d2d2d',
	danger: '#ef0000',
	success: '#12c800',
	warning: '#ff7900',
	announcement: '#ffbe00',
	info: '#0d6bfe',

	gray100: '#f4f4f4',
	gray200: '#ebebeb',
	gray300: '#dddddd',
	gray400: '#bbbbbb',
	gray500: '#999999',
	gray600: '#666666',
	gray700: '#333333',
	gray800: '#2d2d2d',

	white: '#ffffff',
	black: '#000000',

	blue: '#0d6bfe',
	blueLight: '#eaf2ff',
	green: '#12c800',
	teal: '#0c9cb2',

	backdrop: 'rgba(45, 45, 45, 0.3)',

	focusOutline: '#4d90fe',
};

export type Color = keyof typeof defaultColors;

// NOTE: For backwards compatibility. Some JavaScript files might still use the `error` color,
// but we don't want it in the `Color` type (it should be disallowed in TypeScript usage).
Object.assign(defaultColors, {
	error: '#ef0000',
});

export type Colors = Record<Color, CSS.Property.Color>;

export const defaultNativeColors: Colors = {
	...defaultColors,
	backdrop: 'rgba(0, 0, 0, 0.8)',
};
