import { AnyAction } from 'redux'

export const userReducer = (state: User, action: AnyAction): User => {
  const path = process.env.PUBLIC_URL ?? '/'

  switch (action.type) {
    case 'SET_USER':
      if (action.payload === null) {
        localStorage.removeItem('user')
        localStorage.removeItem('users')

        document.cookie.split(';').forEach((c) => {
          document.cookie = `market_analytics_session=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
          document.cookie = `XSRF-TOKEN=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        })
      } else {
        localStorage.setItem('user', JSON.stringify(action.payload))
      }

      return action.payload
    default:
      return state ?? JSON.parse(localStorage.getItem('user') ?? 'null')
  }
}

export const setUser = (user: User | null): AnyAction => {
  return {
    type: 'SET_USER',
    payload: user
  }
}
