import { memo, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox } from '@creditinfo-ui/atoms'

import Icon from '../Icon'

interface Props {
  value: string[]
  onChange: Function
  onSelectAll?: Function
  options: FilterOption[]
  search?: string
  showSelectAll?: boolean
  linked?: boolean
}

interface FilteredOptions {
  data: FilterOption[]
  dataCodeArr: string[]
}

function FilterCheckbox ({ value, onChange, onSelectAll, options, search, showSelectAll = true, linked = false }: Props): JSX.Element {
  const intl = useIntl()
  const [openChilds, setOpenChilds] = useState<string[]>([])

  function isSearched (option: FilterOption): boolean {
    if (search === undefined || search === '') return true

    if (option.node.length === 0) {
      return value.includes(option.optionCode) || search.toLowerCase().split(' ').every(x => option.optionText.toLowerCase().includes(x))
    } else {
      return option.node.some((x: any) => isSearched(x))
    }
  }

  const filteredOptions: FilteredOptions = useMemo(() => {
    const searchedItems = options.filter((item) => isSearched(item))
    const searchedItemsCodeArr = searchedItems.map((item) => item.optionCode)

    return {
      data: searchedItems,
      dataCodeArr: searchedItemsCodeArr
    }
  }, [options, search])

  function onCheckboxChange (option: FilterOption, checked: boolean): void {
    const allChildValues = getChildsValues(option.node)

    let list: string[] = []

    if (checked) {
      list = [...new Set([...value, option.optionCode, ...allChildValues])]
    } else {
      list = value.filter(x => x !== option.optionCode && !allChildValues.includes(x))
    }

    onChange(list)

    if (onSelectAll !== undefined) onSelectAll(list)
  }

  function onCheckboxSelectAll (option: FilterOption, values: string[]): void {
    const allChildValues = option.node.map(x => x.optionCode)

    if (!allChildValues.some(x => !values.includes(x))) {
      if (linked) onChange([...new Set([...values, option.optionCode])])
    } else {
      onChange(values.filter(x => x !== option.optionCode))
    }
  }

  function onOpenChildsClick (code: string): void {
    if (openChilds.includes(code)) {
      setOpenChilds(openChilds.filter(x => x !== code))
    } else {
      setOpenChilds([...openChilds, code])
    }
  }

  function onChildChange (node: FilterOption[], childValue: string[]): void {
    const allChildValues = getChildsValues(node)

    onChange([...new Set([...value.filter(x => !allChildValues.includes(x)), ...childValue])])
  }

  function onCheckAllChange (checked: boolean = false): void {
    if (checked) {
      onChange(getChildsValues(filteredOptions.data))
    } else {
      onChange([])
    }
  }

  const memoizedOnCheckAllChange = useMemo(() => onCheckAllChange, [onCheckAllChange])

  function getChildsValues (options: FilterOption[]): string[] {
    const values = options.map(option => {
      if (option.node.length > 0) {
        return option.node.map(x => [option.optionCode, ...getChildsValues(option.node)]).flat()
      } else {
        return [option.optionCode]
      }
    }).flat()

    return [...new Set([...values])]
  }

  const isSearchActive = Boolean(search)

  useEffect(() => {
    if (isSearchActive) {
      setOpenChilds(filteredOptions.dataCodeArr)
    } else {
      setOpenChilds([])
    }
  }, [search])

  return (
    <div className="pt-6 flex flex-col justify-start gap-6">
      {showSelectAll && filteredOptions.data.length > 1
        ? (
          <Checkbox
            label={intl.formatMessage({ id: 'selectFilters.filter.selectAll' })}
            onChange={function (e) { memoizedOnCheckAllChange(e.target.checked) }}
          />
          )
        : null}

      {filteredOptions.data.map((option) => (
        (
          <div key={option.optionCode}>
            <div className="flex items-center">
              <Checkbox
                isChecked={value.includes(option.optionCode)}
                key={option.optionCode}
                label={option.optionText}
                onChange={function (e) { onCheckboxChange(option, e.target.checked) }}
              />

              {option.node.length > 0
                ? (
                  <div
                    className="ml-2"
                    onClick={function () { onOpenChildsClick(option.optionCode) }}
                  >
                    <Icon type={openChilds.includes(option.optionCode) ? 'chevron_down' : 'chevron_up'} />
                  </div>
                  )
                : null}
            </div>

            {option.node.length > 0 && (openChilds.includes(option.optionCode))
              ? (
                <div className="ml-12">
                  <FilterCheckbox
                    linked={linked}
                    onChange={function (e: string[]) { onChildChange(option.node, e) }}
                    onSelectAll={function (e: string[]) { onCheckboxSelectAll(option, e) }}
                    options={option.node}
                    search={search}
                    showSelectAll={false}
                    value={value}
                  />
                </div>
                )
              : null}
          </div>
        )
      ))}
    </div>
  )
}

export default memo(FilterCheckbox)
