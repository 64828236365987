export const defaultSpacings = {
	// NOTE: Half pixels are evil.
	xxs: '0.2rem',
	xs: '0.5rem',
	sm: '1rem',
	md: '2rem',
	lg: '4rem',
	xl: '6rem',
} as const;

export type Spacings = typeof defaultSpacings;

export const defaultNativeSpacings = {
	xxs: 5,
	xs: 10,
	sm: 15,
	md: 20,
	lg: 30,
} as const;

export type NativeSpacings = typeof defaultNativeSpacings;
