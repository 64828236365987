import { useLayoutEffect } from 'react'
import { prepareStyle, useStyles } from '@creditinfo-ui/styles'

import '@creditinfo-ui/fonts/open-sans.css'
import '../assets/index.css'

const htmlStyle = prepareStyle(() => ({
  fontSize: '.8rem'
}))

const bodyStyle = prepareStyle(utils => ({
  fontFamily: utils.fontFamilies.base,
  fontSize: utils.fontSizes.base,
  lineHeight: utils.lineHeights.base,
  minHeight: '100vh',
  position: 'fixed',
  width: '100%'
}))

const boxSizingStyle = prepareStyle(() => ({
  boxSizing: 'border-box'
}))

export const useGlobalStyles = (): any => {
  const { applyGlobalStyle } = useStyles()

  useLayoutEffect(() => {
    applyGlobalStyle(htmlStyle, 'html')
    applyGlobalStyle(bodyStyle, 'body')
    applyGlobalStyle(boxSizingStyle, '*, *::after, *::before')
  }, [applyGlobalStyle])
}
