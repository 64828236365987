import store from '../store'
import { setErrors } from '../store/errors'
import { setUser } from '../store/user'

interface ApiResult {
  [key: string]: any
}

export async function apiGet (path: string, config: RequestOptions = {}): Promise<ApiResult> {
  await checkSession()

  const url = getUrl(path, config.params)

  return await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: { ...config.headers }
  })
    .then(handleSession)
    .then(handleErrors)
    .then(async response => await response.json())
    .catch(() => ({ error: true }))
}

export async function apiPost (path: string, config: RequestOptions = {}): Promise<ApiResult> {
  await checkSession()

  const url = getUrl(path, config.params)
  const csfr = await getCsrf()

  return await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-XSRF-TOKEN': csfr,
      'Content-Type': 'application/json',
      ...config.headers
    },
    body: JSON.stringify(config.body)
  })
    .then(handleSession)
    .then(handleErrors)
    .then(async response => await response.json())
    .catch(() => ({ error: true }))
}

export async function apiPostForm (path: string, config: RequestOptions = {}): Promise<ApiResult> {
  await checkSession()

  const url = getUrl(path, config.params)
  const csfr = await getCsrf()

  return await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-XSRF-TOKEN': csfr,
      ...config.headers
    },
    body: config.body
  })
    .then(handleSession)
    .then(handleErrors)
    .then(async response => await response.json())
    .catch(() => ({ error: true }))
}

export async function apiPut (path: string, config: RequestOptions = {}): Promise<ApiResult> {
  await checkSession()

  const url = getUrl(path, config.params)
  const csfr = await getCsrf()

  return await fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'X-XSRF-TOKEN': csfr,
      'Content-Type': 'application/json',
      ...config.headers
    },
    body: JSON.stringify(config.body)
  })
    .then(handleSession)
    .then(handleErrors)
    .then(async response => await response.json())
    .catch(() => ({ error: true }))
}

function getUrl (path: string, params?: object): URL {
  const apiUrl = process.env.REACT_APP_API_URL ?? ''
  const language = localStorage.getItem('language') ?? 'et'

  const url = new URL(apiUrl)
  url.pathname = `${url.pathname}/${path}`
  url.search = new URLSearchParams({ lang: language, ...params }).toString()

  return url
}

function getCookie (name: string): string | undefined {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return decodeURIComponent(parts.pop()?.split(';').shift() ?? '')
}

function isSessionExpired (): boolean {
  const session = localStorage.getItem('session') !== null ? new Date(localStorage.getItem('session') ?? 0) : null

  if (session === null) return false

  const now = new Date()
  const expirationDate = new Date(session.getTime() + 110 * 60000)

  return now > expirationDate
}

async function getCsrf (): Promise<string> {
  await fetch(getUrl('csrfCookie'), { credentials: 'include' })

  return getCookie('XSRF-TOKEN') ?? ''
}

async function checkSession (): Promise<void> {
  if (!isSessionExpired()) return

  setUser(null)
  localStorage.removeItem('session')

  await fetch(getUrl('authLogout'), { credentials: 'include' })
}

async function handleSession (response: Response): Promise<Response> {
  localStorage.setItem('session', new Date().toISOString())

  return response
}

async function handleErrors (response: Response): Promise<Response> {
  if (response.ok) return response

  const error = await response.json()

  if (error.messages !== undefined) {
    const messages: string[] = (error.messages ?? []).flat()
    store.dispatch(setErrors(messages))
  }

  console.error(error)

  throw Error()
}
