import { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'

export function useSessionStorage <Type> (storageKey: string, initialState: Type | undefined): [Type, Dispatch<SetStateAction<Type>>] {
  const savedState = sessionStorage.getItem(storageKey) !== null ? JSON.parse(sessionStorage.getItem(storageKey) ?? '') : initialState

  const [value, setValue] = useState(savedState)

  useEffect(() => {
    if (value === initialState) {
      sessionStorage.removeItem(storageKey)
    } else {
      sessionStorage.setItem(storageKey, JSON.stringify(value))
    }
  }, [value, storageKey])

  return [value, setValue]
}

export function resetSessionStorage (resetUser: boolean = false): void {
  const language = localStorage.getItem('language') ?? 'et'
  const user = localStorage.getItem('user')
  const users = localStorage.getItem('users')

  sessionStorage.clear()
  localStorage.clear()
  localStorage.setItem('language', language)

  if (resetUser) return

  if (user !== null) localStorage.setItem('user', user)
  if (users !== null) localStorage.setItem('users', users)
}
