import { Keyframes } from '@creditinfo-ui/fela';
import { Message } from '@creditinfo-ui/messages';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { isNil } from 'ramda';
import { cx } from 'ramda-extension';
import { forwardRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { m } from '../messages';
import { Spacing, VerticalSpacing } from '../types';
import { resolveVerticalMargin } from '../utils';
import { Text } from './Text';

type SpinnerSize = 'small' | 'medium';
type SpinnerVariant = 'colored' | 'light' | 'dark';
type SpinnerMessage = 'default' | MessageDescriptor;

interface SpinnerVariantPreset {
	animations: Keyframes[];
	borderColor: string;
	borderInlineStartColor: string;
}

const spinnerSizes = {
	small: '1.6rem',
	medium: '4.8rem',
};

const SPINNER_BORDER_WIDTH = '0.4rem';

export interface ContainerStyleProps {
	hasBackground: boolean;
	isDelayed: boolean;
	marginBottom: Spacing;
	marginTop: Spacing;
}

const containerStyle = prepareStyle(
	(utils, { hasBackground, isDelayed, marginBottom, marginTop }: ContainerStyleProps) => ({
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		extend: [
			{
				condition: isDelayed,
				style: {
					animationDelay: '3s',
					animationDuration: '2s',
					animationFillMode: 'forwards',
					animationName: {
						'0%': { opacity: 0 },
						'100%': { opacity: 1 },
					},
					opacity: 0,
				},
			},
			{
				condition: hasBackground,
				style: {
					backgroundColor: 'white',
					borderRadius: utils.borders.radii.basic,
					boxShadow: utils.boxShadows.basic,
					padding: utils.spacings.md,
				},
			},
			{
				condition: marginBottom !== 'none',
				style: {
					marginBottom: utils.spacings[marginBottom],
				},
			},
			{
				condition: marginTop !== 'none',
				style: {
					marginTop: utils.spacings[marginTop],
				},
			},
		],
	})
);

const loaderStyle = prepareStyle<{
	size: SpinnerSize;
	variant: SpinnerVariant;
}>(({ colors, borders, transparentize }, { variant, size }) => {
	const spinnerAnimationPresets: Record<SpinnerVariant, SpinnerVariantPreset> = {
		colored: {
			animations: [{ '50%': { borderInlineStartColor: colors.green } }],
			borderColor: transparentize(0.8, colors.blue),
			borderInlineStartColor: colors.blue,
		},
		dark: {
			animations: [],
			borderColor: transparentize(0.65, colors.black),
			borderInlineStartColor: colors.black,
		},
		light: {
			animations: [],
			borderColor: transparentize(0.65, colors.white),
			borderInlineStartColor: colors.white,
		},
	};

	return {
		animationDuration: '1.2s',
		animationIterationCount: 'infinite',
		animationName: [
			{
				'0%': { transform: 'rotate(0)' },
				'100%': { transform: 'rotate(360deg)' },
			},
			...spinnerAnimationPresets[variant].animations,
		],
		animationTimingFunction: 'cubic-bezier(.445, .05, .55, .95)',
		borderColor: spinnerAnimationPresets[variant].borderColor,
		borderInlineStartColor: spinnerAnimationPresets[variant].borderInlineStartColor,
		borderRadius: borders.radii.round,
		borderStyle: 'solid',
		borderWidth: SPINNER_BORDER_WIDTH,
		display: 'inline-block',
		height: spinnerSizes[size],
		width: spinnerSizes[size],
	};
});

const loaderMessageStyle = prepareStyle(utils => ({
	paddingInlineStart: utils.spacings.md,
}));

export interface SpinnerProps {
	className?: string;
	customStyle?: Style<ContainerStyleProps>;
	hasBackground?: boolean;
	isDelayed?: boolean;
	message?: SpinnerMessage;
	size?: SpinnerSize;
	variant?: SpinnerVariant;
	verticalMargin?: VerticalSpacing;
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
	(
		{
			className,
			customStyle,
			hasBackground = false,
			isDelayed = false,
			message: messageProp,
			size = 'medium',
			variant = 'colored',
			verticalMargin,
		}: SpinnerProps,
		ref
	) => {
		const intl = useIntl();
		const { applyStyle } = useStyles();
		const message = messageProp === 'default' ? m.loading : messageProp;
		const { marginBottom, marginTop } = resolveVerticalMargin(verticalMargin);

		return (
			<div
				aria-busy="true"
				aria-live="assertive"
				aria-valuemin={0}
				aria-valuemax={100}
				aria-valuetext={intl.formatMessage(message ?? m.loading)}
				className={cx(
					applyStyle([containerStyle, customStyle], {
						hasBackground,
						isDelayed,
						marginBottom,
						marginTop,
					}),
					className
				)}
				ref={ref}
				role="progressbar"
			>
				<span role="img" className={applyStyle(loaderStyle, { variant, size })} />
				{!isNil(message) && (
					<Text aria-hidden="true" customStyle={loaderMessageStyle} variant="tagline">
						<Message {...message} />
					</Text>
				)}
			</div>
		);
	}
);

Spinner.displayName = 'Spinner';
