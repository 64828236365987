import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Checkbox, Radio, Text, Textarea, TextInput } from '@creditinfo-ui/atoms'

import { apiGet, apiPost } from '../../hooks/useApi'
import { useSessionStorage } from '../../hooks/useStorage'
import Icon from '../Icon'
import StepsIndicator from './StepsIndicator'

interface Props {
  tabs: string[]
  stats?: Stats
  formData: OrderFormData
  setFormData: Function
  payment?: boolean
  onPayment?: Function
  onCancelPayment?: Function
}

export default function StepOrderForm ({ tabs, stats, formData, setFormData, payment = false, onPayment, onCancelPayment }: Props): JSX.Element {
  const intl = useIntl()

  const user = useSelector((state: any) => state.user)
  const [activePage] = useSessionStorage('activePage', '')
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [regCodeErrorId, setRegCodeErrorId] = useState('')
  const [regCode, setRegCode] = useState('')
  const [regCodeChecked, setRegCodeChecked] = useState(false)
  const [regCodeCompany, setRegCodeCompany] = useState('')
  const requiredMark = '*'

  const disclaimer = useMemo(() => {
    switch (activePage) {
      case '/own-sample':
        return intl.formatMessage({ id: 'order.summary.disclaimer' })
      case '/select-sample':
        return intl.formatMessage({ id: 'order.summary.disclaimer' })
      case '/global-sample':
        return intl.formatMessage({ id: 'order.summary.disclaimerGlobal' })
      default:
        return ''
    }
  }, [intl.locale, activePage])

  useEffect(() => {
    async function getPaymentMethods (): Promise<void> {
      const methods = await apiGet('getPaymentMethods')

      setPaymentMethods(methods.map((x: PaymentMethod) => x))
    }

    getPaymentMethods().catch(console.error)
    setRegCode(formData.regCode ?? '')

    if (activePage === '/global-sample') setFormData({ ...formData, acceptTerms: true })
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      await regCodeChange(regCode)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [regCode])

  async function regCodeChange (code: string): Promise<void> {
    setRegCodeCompany('')
    setRegCodeErrorId('')

    if (regCodeChecked || code.length >= 8) {
      await checkRegCode(code)
      setRegCodeChecked(true)
    }
  }

  async function checkRegCode (code: string): Promise<void> {
    const { regCodesAccepted, regCodesIncompleteData, regCodesNotExist, error } = await apiPost('checkRegCodes', {
      body: {
        regCodes: [code.trim()]
      }
    })

    if (error !== undefined) return

    if (regCodesAccepted.length > 0) {
      setFormData({ ...formData, regCode: code })
      setRegCodeCompany(regCodesAccepted.find((x: Company) => x.regCode === code).companyName)
      setRegCodeErrorId('')
    } else if (regCodesIncompleteData.length > 0) {
      setFormData({ ...formData, regCode: null })
      setRegCodeCompany('')
      setRegCodeErrorId('orderForm.regCode.incompleteData')
    } else if (regCodesNotExist.length > 0) {
      setFormData({ ...formData, regCode: null })
      setRegCodeCompany('')
      setRegCodeErrorId('orderForm.regCode.notExist')
    }
  }

  return (
    <div className="container my-14 mx-auto xl:px-64 place-self-start">
      <div className="mb-8 md:mb-14">
        <Text
          message={{ id: 'orderForm.title' }}
          variant="viewTitle"
        />
      </div>

      <StepsIndicator
        activeStep={2}
        steps={tabs}
      />

      {payment && paymentMethods.length > 0 && onPayment !== undefined && onCancelPayment !== undefined
        ? (
          <div
            className="absolute top-0 right-0 bottom-0 left-0 z-50 bg-gray-light bg-opacity-70 flex items-center justify-center"
            onClick={function () { onCancelPayment() }}
          >
            <div
              className="p-8 grid grid-cols-2 gap-6 bg-white border border-gray-brand"
              onClick={function (e) { e.stopPropagation() }}
            >
              <div className="col-span-2 uppercase font-bold text-center">
                <FormattedMessage id="orderForm.paymentMethod" />

                <div
                  className="cursor-pointer float-right"
                  onClick={function () { onCancelPayment() }}
                >
                  <Icon type="close" />
                </div>
              </div>

              {paymentMethods.map(method => (
                <img
                  alt={method.name}
                  className="h-14 w-40 p-3 object-contain border border-grey cursor-pointer"
                  key={method.code}
                  onClick={function () { onPayment(method.code) }}
                  src={method.logoUrl}
                />
              ))}
            </div>
          </div>
          )
        : null}

      <div className="pt-6 grid lg:grid-cols-3 gap-6 border-t-[3px] border-red-brand">
        <div className="lg:col-span-2">
          <Text
            message={{ id: 'orderForm.subTitle' }}
            variant="extraTitle"
          />

          <div className="pt-12 flex flex-col gap-6">
            {activePage !== '/global-sample' || (activePage === '/global-sample' && user === null)
              ? (
                <>
                  <div className="flex gap-12">
                    <Radio
                      isChecked={!formData.isPrivateCustomer}
                      label={intl.formatMessage({ id: 'orderForm.isCompany' })}
                      onChange={function (e) { setFormData({ ...formData, isPrivateCustomer: !e.target.checked }) }}
                    />

                    <Radio
                      isChecked={formData.isPrivateCustomer}
                      label={intl.formatMessage({ id: 'orderForm.isPrivate' })}
                      onChange={function (e) { setFormData({ ...formData, isPrivateCustomer: e.target.checked }) }}
                    />
                  </div>

                  <div className="">
                    <FormattedMessage id="orderForm.email" />

                    <span className="ml-1 text-red-brand">
                      {requiredMark}
                    </span>

                    <TextInput
                      maxLength={50}
                      onChange={function (e) { setFormData({ ...formData, email: e.target.value }) }}
                      value={formData.email}
                    />
                  </div>

                  {!formData.isPrivateCustomer
                    ? (
                      <div className="">
                        <FormattedMessage id="orderForm.regCode" />

                        <span className="ml-1 text-red-brand">
                          {requiredMark}
                        </span>

                        <TextInput
                          onBlur={async function (e) { await checkRegCode(e.target.value) }}
                          onChange={async function (e) { setRegCode(e.target.value) }}
                          value={regCode}
                        />

                        <div className="h-8">
                          {regCodeErrorId !== '' && regCode !== ''
                            ? (
                              <div className="text-red-brand">
                                <FormattedMessage id={regCodeErrorId} />
                              </div>
                              )
                            : null}

                          {regCodeErrorId === '' && regCodeCompany !== ''
                            ? (
                              <div className="text-green-brand">
                                {regCodeCompany}
                              </div>
                              )
                            : null}
                        </div>
                      </div>
                      )
                    : null}
                </>
                )
              : null}

            {activePage === '/global-sample'
              ? (
                <div className="">
                  <FormattedMessage id="orderForm.info" />

                  <Textarea
                    isResizable={false}
                    maxLength={1000}
                    onChange={function (e) { setFormData({ ...formData, info: e.target.value }) }}
                    rows={5}
                    value={formData.info}
                  />
                </div>
                )
              : null}

            {activePage !== '/global-sample'
              ? (
                <div className="flex">
                  <Checkbox
                    isChecked={formData.acceptTerms}
                    label={intl.formatMessage({ id: 'orderForm.acceptTerms' }, {
                      termsLink: (
                        <a
                          className="text-teal-brand"
                          href={intl.formatMessage({ id: 'orderForm.acceptTermsUrl' })}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {intl.formatMessage({ id: 'orderForm.acceptTermsLink' })}
                        </a>
                      )
                    })}
                    onChange={function (e) { setFormData({ ...formData, acceptTerms: e.target.checked }) }}
                  />

                  <span className="ml-1 text-red-brand">
                    {requiredMark}
                  </span>
                </div>
                )
              : null}
          </div>
        </div>

        <div>
          <div className="py-6 px-5 bg-silver font-bold">
            {stats?.filtersCount !== undefined
              ? (
                <div className="pb-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.filtersName" />
                  </div>

                  {stats?.filtersCount !== null ? intl.formatNumber(stats?.filtersCount) : '-'}
                </div>
                )
              : null}

            {stats?.dataFieldsCount !== undefined
              ? (
                <div className="pb-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.fieldsName" />
                  </div>

                  {stats?.dataFieldsCount !== null ? intl.formatNumber(stats?.dataFieldsCount) : '-'}
                </div>
                )
              : null}

            {stats?.companiesCount !== undefined
              ? (
                <div className="pb-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.countName" />
                  </div>

                  {stats?.companiesCount !== null ? intl.formatNumber(stats?.companiesCount) : '-'}
                </div>
                )
              : null}

            {stats?.sum !== undefined
              ? (
                <div className="pt-3 flex justify-between border-t border-gray-other">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.priceName" />
                  </div>

                  {stats?.sum !== null ? intl.formatNumber(stats?.sum, { style: 'currency', currency: 'EUR' }) : '-'}
                </div>
                )
              : null}

            {stats?.sumWithVat !== undefined
              ? (
                <div className="pt-3 flex justify-between">
                  <div className="font-bold">
                    <FormattedMessage id="order.summary.priceWithVatName" />
                  </div>

                  {stats?.sumWithVat !== null ? intl.formatNumber(stats?.sumWithVat, { style: 'currency', currency: 'EUR' }) : '-'}
                </div>
                )
              : null}

            <div className="pt-3 font-normal text-sm">
              {disclaimer}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
