import { format } from 'date-fns'
import { enUS, et } from 'date-fns/locale'

type LocaleString = 'en' | 'et'

const locales = {
  en: enUS,
  et
}

export const formatDateTimeToLocale = (
  date: Date,
  localeStr: string
): string => {
  const locale = locales[localeStr as LocaleString] || enUS
  const datePart: string = format(date, 'dd.MM.yyyy', { locale })
  const timePart: string = format(date, 'p', { locale })

  return `${datePart} ${timePart}`
}

export const formatDate = (date: Date): string => format(date, 'dd.MM.yyyy')
