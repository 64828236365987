import { FormEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, TextInput } from '@creditinfo-ui/atoms'

import { apiGet } from '../../hooks/useApi'
import { db } from '../../hooks/useDexieDB'
import SelectGroup from './SelectGroup'

interface Props {
  setCodesAccepted: Function
  onReset: Function
  codesAccepted: number
  codesIncompleteData: number
  codesNotExist: number
}

export default function CompanySelectSelect ({ setCodesAccepted, onReset, codesAccepted, codesIncompleteData, codesNotExist }: Props): JSX.Element {
  const intl = useIntl()

  const defaultPagination: GroupPagination = {
    limit: 10,
    page: 1,
    total: 1,
    sort: 'AZ'
  }

  const [searchText, setSearchText] = useState('')
  const [codesFound, setCodesFound] = useState<Company[]>([])
  const [searchDone, setSearchDone] = useState(false)
  const [accepted, setAccepted] = useState<Company[]>([])
  const [incompleteData, setIncompleteData] = useState<Company[]>([])
  const [notExist, setNotExist] = useState<Company[]>([])
  const [acceptedPagination, setAcceptedPagination] = useState<GroupPagination>({ ...defaultPagination })
  const [incompletePagination, setIncompletePagination] = useState<GroupPagination>({ ...defaultPagination })
  const [notExistPagination, setNotExistPagination] = useState<GroupPagination>({ ...defaultPagination })

  useEffect(() => {
    async function updateCodesFound (): Promise<void> {
      const selectedCodes = await Promise.all(codesFound.map(async x => await setSelected(x)))

      setCodesFound(selectedCodes)
    }

    updateCodesFound().catch(console.error)
  }, [codesAccepted])

  useEffect(() => {
    async function getDbData (): Promise<void> {
      const a = await db.codesAccepted.count()
      const i = await db.codesIncompleteData.count()
      const n = await db.codesNotExist.count()

      setAcceptedPagination({ ...acceptedPagination, total: a })
      setIncompletePagination({ ...incompletePagination, total: i })
      setNotExistPagination({ ...notExistPagination, total: n })
    }

    getDbData().catch(console.error)
  }, [])

  useEffect(() => {
    async function getDbData (): Promise<void> {
      const { page, limit, sort } = acceptedPagination
      const offset = (page - 1) * limit

      const a = db.codesAccepted.orderBy('companyName')

      if (sort === 'ZA') a.reverse()

      setAccepted(await a.offset(offset).limit(limit).toArray())
    }

    getDbData().catch(console.error)
  }, [acceptedPagination])

  useEffect(() => {
    async function getDbData (): Promise<void> {
      const { page, limit, sort } = incompletePagination
      const offset = (page - 1) * limit

      const a = db.codesIncompleteData.orderBy('companyName')

      if (sort === 'ZA') a.reverse()

      setIncompleteData(await a.offset(offset).limit(limit).toArray())
    }

    getDbData().catch(console.error)
  }, [incompletePagination])

  useEffect(() => {
    async function getDbData (): Promise<void> {
      const { page, limit, sort } = notExistPagination
      const offset = (page - 1) * limit

      const a = db.codesNotExist.orderBy('regCode')

      if (sort === 'ZA') a.reverse()

      setNotExist(await a.offset(offset).limit(limit).toArray())
    }

    getDbData().catch(console.error)
  }, [notExistPagination])

  async function setSelected (code: Company): Promise<Company> {
    const accepted = await db.codesAccepted.get(code.regCode)
    const incomplete = await db.codesIncompleteData.get(code.regCode)

    return {
      ...code,
      selected: accepted !== undefined || incomplete !== undefined
    }
  }

  async function onSearchSubmit (e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()

    if (searchText.trim() === '') return

    const { companies, error } = await apiGet('findCompanies', {
      params: {
        search: searchText,
        count: 10
      }
    })

    if (error !== undefined) return

    const selectedCodes = await Promise.all(companies.map(async (x: Company) => await setSelected(x)))

    setCodesFound(selectedCodes)
    setSearchDone(true)
  }

  async function onAddCode (code: Company): Promise<void> {
    await db.codesAccepted.add(code)

    setAccepted(await db.codesAccepted.orderBy('companyName').limit(100).toArray())
    setCodesAccepted(db.codesAccepted.count())
  }

  async function onRemoveCode (code: Company): Promise<void> {
    await db.codesAccepted.delete(code.regCode)

    setAccepted(await db.codesAccepted.orderBy('companyName').limit(100).toArray())
    setCodesAccepted(db.codesAccepted.count())
  }

  return (
    <>
      <div className="mb-14 flex items-center justify-between">
        <form
          className="lg:w-2/3 flex gap-4"
          onSubmit={onSearchSubmit}
        >
          <TextInput
            onChange={function (e) { setSearchText(e.target.value) }}
            placeholder={intl.formatMessage({ id: 'selectCompanies.select.searchPlaceholder' })}
            value={searchText}
          />

          <Button type="submit">
            <FormattedMessage id="selectCompanies.select.search" />
          </Button>
        </form>

        <Button onClick={function () { onReset() }}>
          <FormattedMessage id="selectCompanies.select.reset" />
        </Button>
      </div>

      {searchDone && codesFound.length > 0
        ? <SelectGroup
            codes={codesFound}
            onAddCode={onAddCode}
            pagination={{ limit: 10, page: 1, total: codesFound.length, sort: 'AZ' }}
            setPagination={function () {}}
          />
        : null}

      {searchDone && codesFound.length === 0
        ? (
          <div className="mb-8 md:mb-14">
            <FormattedMessage id="selectCompanies.select.searchNotFound" />
          </div>
          )
        : null}

      <SelectGroup
        codes={accepted}
        onRemoveCode={onRemoveCode}
        pagination={acceptedPagination}
        setPagination={setAcceptedPagination}
        titleMessageId="selectCompanies.select.acceptedList"
      />

      <SelectGroup
        codes={incompleteData}
        pagination={incompletePagination}
        setPagination={setIncompletePagination}
        titleMessageId="selectCompanies.select.incompleteDataList"
      />

      <SelectGroup
        codes={notExist}
        pagination={notExistPagination}
        setPagination={setNotExistPagination}
        titleMessageId="selectCompanies.select.notExistList"
      />
    </>
  )
}
