import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Tooltip } from '@creditinfo-ui/atoms'

interface Props {
  stats: Stats
  isNextDisabled: boolean
  isStatsLoading: boolean
  onPreviousStep: Function
  onNextStep: Function
  nextButtonText: string
  filterCountSectionClassName?: string
  fieldsCountSectionClassName?: string
}

export default function Footer ({ stats, onPreviousStep, onNextStep, isNextDisabled, isStatsLoading, nextButtonText, filterCountSectionClassName, fieldsCountSectionClassName }: Props): JSX.Element {
  const intl = useIntl()

  return (
    <div className="bg-silver flex items-center">
      <div className="min-w-full px-4 md:px-6 py-4 flex items-center justify-between gap-4 xl:gap-14">
        <div className="container mx-auto xl:px-64 flex flex-wrap md:flex-nowrap justify-between items-center">
          <div className="order-2 md:order-1">
            <Button
              onClick={function () { onPreviousStep() }}
              variant={['primary', { isOutlined: true }]}
            >
              <FormattedMessage id="footer.back" />
            </Button>
          </div>

          <div className="order-1 md:order-2 w-full grow mr-4 mb-4 md:mb-0 flex flex-col md:flex-row justify-end md:items-center font-bold">
            {isStatsLoading
              ? (
                <div className="lg:-ml-1 lg:mr-3 flex items-center justify-center">
                  <svg
                    className="animate-spin h-8 w-8 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />

                    <path
                      className="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                )
              : null}

            {!isStatsLoading && stats?.companiesCount !== undefined
              ? (
                <div className="py-2 md:py-0 md:px-6 border-r-0 border-b md:border-r last:border-b-0 last:border-r-0 md:border-b-0 border-gray-other">
                  <FormattedMessage id="footer.count" />

                  { stats?.companiesCount !== null ? intl.formatNumber(stats?.companiesCount) : '-'}
                </div>
                )
              : null}
            {!isStatsLoading && stats?.filtersCount !== undefined
              ? (
                <div className={`py-2 md:py-0 md:px-6 border-r-0 border-b md:border-r last:border-b-0 last:border-r-0 md:border-b-0 border-gray-other ${filterCountSectionClassName ?? ''}`}>
                  <FormattedMessage id="footer.filter" />

                  { stats?.filtersCount !== null ? intl.formatNumber(stats?.filtersCount) : '-'}
                </div>
                )
              : null}

            {!isStatsLoading && stats?.dataFieldsCount !== undefined
              ? (
                <div className={`py-2 md:py-0 md:px-6 border-r-0 border-b md:border-r last:border-b-0 last:border-r-0 md:border-b-0 border-gray-other ${fieldsCountSectionClassName ?? ''}`}>
                  <FormattedMessage id="footer.fields" />

                  { stats?.dataFieldsCount !== null ? intl.formatNumber(stats?.dataFieldsCount) : '-'}
                </div>
                )
              : null}

            {!isStatsLoading && stats?.sum !== undefined
              ? (
                <div className="py-2 md:py-0 md:px-6 border-r-0 border-b md:border-r last:border-b-0 last:border-r-0 md:border-b-0 border-gray-other">
                  <FormattedMessage id="footer.price" />

                  { stats?.sum !== null ? intl.formatNumber(stats?.sum, { style: 'currency', currency: 'EUR' }) + intl.formatMessage({ id: 'footer.vat' }) : '-' }
                </div>
                )
              : null}
          </div>

          <div className="order-3">
            {(stats?.sum ?? 0) > 10000
              ? (
                <Tooltip tooltip={<FormattedMessage
                  id="footer.limit"
                                  />}
                >
                  <span>
                    <Button disabled>
                      {nextButtonText}
                    </Button>
                  </span>
                </Tooltip>
                )
              : (
                <Button
                  disabled={isNextDisabled}
                  onClick={function () { onNextStep() }}
                >
                  {nextButtonText}
                </Button>
                )}
          </div>
        </div>
      </div>
    </div>
  )
}
