export const defaultMaxWidths = {
	xs: '360px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1400px',
};

export type MaxWidths = typeof defaultMaxWidths;
