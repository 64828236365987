import { resetDb } from '../../hooks/useDexieDB'

import Select from './Select'
import Upload from './Upload'

interface Props {
  codesUploaded: boolean
  codesAccepted: number
  codesIncompleteData: number
  codesNotExist: number
  setCodesUploaded: Function
  setCodesAccepted: Function
  setCodesNotExist: Function
  setCodesIncompleteData: Function
}

export default function CompanySelect ({ codesUploaded = false, codesAccepted, codesIncompleteData, codesNotExist, setCodesUploaded, setCodesAccepted, setCodesNotExist, setCodesIncompleteData }: Props): JSX.Element {
  async function onReset (): Promise<void> {
    setCodesAccepted([])
    setCodesIncompleteData([])
    setCodesNotExist([])
    setCodesUploaded(false)
    await resetDb()
  }

  if (!codesUploaded) {
    return (
      <Upload
        setCodesAccepted={function (count: number) { setCodesAccepted(count) }}
        setCodesIncompleteData={function (count: number) { setCodesIncompleteData(count) }}
        setCodesNotExist={function (count: number) { setCodesNotExist(count) }}
        setCodesUploaded={function (value: boolean) { setCodesUploaded(value) }}
      />
    )
  } else {
    return (
      <Select
        codesAccepted={codesAccepted}
        codesIncompleteData={codesIncompleteData}
        codesNotExist={codesNotExist}
        onReset={async function () { await onReset() }}
        setCodesAccepted={function (count: number) { setCodesAccepted(count) }}
      />
    )
  }
}
