import { transparentize } from 'polished';
import { defaultColors } from './colors';

export const defaultBoxShadows = {
	basic: '0 5px 15px 0 rgba(0, 0, 0, 0.09)',
	footer: '0 -20px 40px 0 rgba(0, 0, 0, 0.04)',
	invalid: `0 0 15px 0 ${transparentize(0.7, defaultColors.danger)}`,
	menu: '0 -1px 20px 0 rgba(0, 0, 0, 0.2)',
	focusDrop: (color: string) => `0 0 15px 0 ${color}`,
	focusInset: (color: string) => `inset 0 0 0 2px ${color}`,
};

export type BoxShadow = keyof typeof defaultBoxShadows;
export type BoxShadows = typeof defaultBoxShadows;

interface NativeBoxShadowDefinition {
	elevation: number;
	shadowColor: string;
	shadowOffset: {
		height: number;
		width: number;
	};
	shadowOpacity: number;
	shadowRadius: number;
}

export const defaultNativeBoxShadows = {
	basic: {
		elevation: 4,
		shadowColor: '#000000',
		shadowOffset: {
			height: 0,
			width: 0,
		},
		shadowOpacity: 0.1,
		shadowRadius: 5,
	},
	fab: {
		elevation: 3,
		shadowColor: '#000000',
		shadowOffset: {
			height: 1,
			width: 0,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
	},
	stepper: {
		elevation: 4,
		shadowColor: '#000000',
		shadowOffset: {
			height: 5,
			width: 0,
		},
		shadowOpacity: 0.05,
		shadowRadius: 5,
	},
};

export type NativeBoxShadow = keyof typeof defaultNativeBoxShadows;
export type NativeBoxShadows = Record<NativeBoxShadow, NativeBoxShadowDefinition>;
