import type { PartialDeep } from 'type-fest';

import { Borders, NativeBorders, defaultBorders, defaultNativeBorders } from './borders';
import {
	BoxShadows,
	NativeBoxShadows,
	defaultBoxShadows,
	defaultNativeBoxShadows,
} from './boxShadows';
import { Colors, defaultColors, defaultNativeColors } from './colors';
import { Environment, defaultEnvironment } from './environment';
import { FontFamilies, defaultFontFamilies } from './fontFamilies';
import { FontSizes, defaultFontSizes } from './fontSizes';
import { FontWeights, defaultFontWeights } from './fontWeights';
import { LineHeights, defaultLineHeights } from './lineHeights';
import { MaxWidths, defaultMaxWidths } from './maxWidths';
import { Sizes, defaultSizes } from './sizes';
import { NativeSpacings, Spacings, defaultNativeSpacings, defaultSpacings } from './spacings';
import { Transitions, defaultTransitions } from './transitions';
import { ZIndices, defaultZIndices } from './zIndices';

export * from './borders';
export * from './boxShadows';
export * from './colors';
export * from './environment';
export * from './fontFamilies';
export * from './fontSizes';
export * from './fontWeights';
export * from './lineHeights';
export * from './maxWidths';
export * from './sizes';
export * from './spacings';
export * from './transitions';
export * from './zIndices';

export interface Theme {
	borders: Borders;
	boxShadows: BoxShadows;
	colors: Colors;
	environment: Environment;
	fontFamilies: FontFamilies;
	fontSizes: FontSizes;
	fontWeights: FontWeights;
	lineHeights: LineHeights;
	maxWidths: MaxWidths;
	sizes: Sizes;
	spacings: Spacings;
	transitions: Transitions;
	zIndices: ZIndices;
}

export type PartialTheme = PartialDeep<Theme>;

export const defaultTheme: Theme = {
	borders: defaultBorders,
	boxShadows: defaultBoxShadows,
	colors: defaultColors,
	environment: defaultEnvironment,
	fontFamilies: defaultFontFamilies,
	fontSizes: defaultFontSizes,
	fontWeights: defaultFontWeights,
	lineHeights: defaultLineHeights,
	maxWidths: defaultMaxWidths,
	sizes: defaultSizes,
	spacings: defaultSpacings,
	transitions: defaultTransitions,
	zIndices: defaultZIndices,
};

export interface NativeTheme {
	borders: NativeBorders;
	boxShadows: NativeBoxShadows;
	colors: Colors;
	spacings: NativeSpacings;
}

export type PartialNativeTheme = PartialDeep<NativeTheme>;

export const defaultNativeTheme: NativeTheme = {
	borders: defaultNativeBorders,
	boxShadows: defaultNativeBoxShadows,
	colors: defaultNativeColors,
	spacings: defaultNativeSpacings,
};
