import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createRenderer, StylesProvider, PartialTheme } from '@creditinfo-ui/styles'

import Wrapper from './components/Wrapper'
import Home from './views/Home'
import SignIn from './views/SignIn'
import NotFound from './views/NotFound'
import OwnSample from './views/OwnSample'
import SelectSample from './views/SelectSample'
import GlobalSample from './views/GlobalSample'
import PaymentCallback from './views/PaymentCallback'
import OrderSuccess from './views/OrderSuccess'
import InquirySuccess from './views/InquirySuccess'
import Archive from './views/Archive'
import LanguageHandler from './components/LanguageHandler'

const renderer = createRenderer()
const theme: PartialTheme = {
  fontSizes: {
    extraTitle: { xs: '2rem', md: '2.5rem' }
  }
}

export default function App (): JSX.Element {
  return (
    <StylesProvider
      direction="ltr"
      renderer={renderer}
      theme={theme}
    >
      <Wrapper>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <LanguageHandler />
          <Routes>
            <Route
              element={<Home />}
              path="/"
            />

            <Route
              element={<SignIn />}
              path="/sign-in"
            />

            <Route
              element={<OwnSample />}
              path="/own-sample"
            />

            <Route
              element={<SelectSample />}
              path="/select-sample"
            />

            <Route
              element={<GlobalSample />}
              path="/global-sample"
            />

            <Route
              element={<PaymentCallback />}
              path="/payment-callback"
            />

            <Route
              element={<OrderSuccess />}
              path="/order-success"
            />

            <Route
              element={<InquirySuccess />}
              path="/inquiry-success"
            />

            <Route
              element={<Archive />}
              path="/archive"
            />

            <Route
              element={<NotFound />}
              path="*"
            />
          </Routes>
        </BrowserRouter>
      </Wrapper>
    </StylesProvider>
  )
}
